import React, { useEffect } from 'react';
import { LocationData, VehicleTrackingData } from 'src/types';
import { RouteData } from 'src/types/routes';
import { AssetGroupType } from '.';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SuperSearchBar } from './SuperSearchBar';

export interface AssetOption {
  type: AssetGroupType;
  name: string;
  externalId?: string;
  key: string;
  model: LocationData | VehicleTrackingData | RouteData;
  subtitle: string;
}

interface Props {
  selectedAssetType: AssetGroupType;
  onAssetOptionSelected: (option: AssetOption) => void;
  onOpen: () => void;
  onClose: () => void;
  isVisible: boolean;
  locations: LocationData[];
  vehicles: VehicleTrackingData[];
  routes: RouteData[];
  timeframeIndex: number;
}

export const SuperSearchOverlay = (props: Props) => {
  const searchInputRef = React.useRef<HTMLInputElement | null>(null);
  const { onClose, isVisible } = props;

  useEffect(() => {
    const handleShortcut = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        if (isVisible) {
          props.onClose();
        } else {
          props.onOpen();
        }
      }
    };
    document.addEventListener('keydown', handleShortcut);


    if (isVisible && searchInputRef.current) {
      searchInputRef.current.focus();
    }
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    if (isVisible) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keydown', handleShortcut);
    };
  }, [onClose, isVisible]);

  if (!isVisible) return null;

  return (
    <div
      style={{ position: 'fixed', inset: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 50000 }}
      onClick={onClose}
    >
      <div
        style={{ backgroundColor: '#2C2C2C', padding: '24px', borderRadius: '12px', boxShadow: '0 4px 6px rgba(0,0,0,0.3)', width: '50%', minWidth: '400px', position: 'relative', color: 'white', marginTop: '-20%' }}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '32px', color: 'white', zIndex: 10 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <SuperSearchBar {...props} inputRef={searchInputRef} />
      </div>
    </div>
  );
};
