import { Close, KeyboardArrowDown, KeyboardArrowUp, MapOutlined } from "@mui/icons-material";
import { IconButton, MenuItem, Paper, Select, Slider, Tab, Tabs, Tooltip } from "@mui/material";
import './TimeSeriesGraphView.css';
import * as React from "react";
import { coerceDateToHour } from "../../../reducers/Ratings";
import { relevantRatingKeys } from "../../../actions/Ratings";
import { GraphWidget, GraphDataset } from "../../../components/shared/GraphWidget";
import { cardBackgroundColor } from "../../../constants";
import { HourRatingsData, BlurbsByIndex, WeatherConditions, RatingsByIndex, SelectedCityState } from "../../../types";
import { RatingKey, formatRatingKey } from "../../../types/RatingKey";
import moment from "moment";
import { colorForImpactLevel, ImpactLevel } from "src/types/routes";

interface TimeSeriesDataPoint {
    value: number;
    time: Date;
}

interface TimeSeriesGraphViewProps {
    timezone?: string;
    selectedCityState: SelectedCityState;
    selectedGraphView: string;
    graphViews?: any[];
    isNowcastGraph?: boolean;
    extraStyles?: React.CSSProperties;
    now?: Date;
    // only disabled for subhourly data
    zoomDisabled?: boolean;

    onValueClicked: (date: Date) => void;
    onCloseClicked?: () => void;
    setSelectedGraphView?: (graphView: string) => void;
    setTilingLayer?: (layer: string) => void;
}

interface TimeSeriesRatingProps extends TimeSeriesGraphViewProps {
    ratings?: RatingsByIndex;
    selectedRatingKey?: RatingKey;
    showDisruptionIndex: boolean;
    showWildfireIndices: boolean;
    showLightningProbability: boolean;

    blurbs: BlurbsByIndex;
    onRatingKeySelected: (ratingKey: RatingKey) => void;
}

interface TimeSeriesWeatherProps extends TimeSeriesGraphViewProps {
    weatherConditions?: WeatherConditions[];
    selectedWeatherProperty?: string;

    onWeatherPropertyChanged: (ratingKey: RatingKey) => void;
}


export const TimeSeriesGraphView = (props: TimeSeriesWeatherProps & TimeSeriesRatingProps) => {
    const { timezone, weatherConditions, ratings, selectedCityState, selectedGraphView, selectedWeatherProperty, selectedRatingKey, blurbs, showDisruptionIndex, showWildfireIndices, isNowcastGraph, graphViews, now, onValueClicked, onCloseClicked, onWeatherPropertyChanged, onRatingKeySelected, setSelectedGraphView, setTilingLayer } = props;

    const [weatherDatasets, setWeatherDatasets] = React.useState<GraphDataset<TimeSeriesDataPoint>[]>([]);
    const [ratingDatasets, setRatingDatasets] = React.useState<GraphDataset<HourRatingsData>[]>([]);
    const [collapsed, setCollapsed] = React.useState(false);
    const [zoom, setZoom] = React.useState(4);

    let weatherTabs: { label: string, name: string }[] = [
        { label: 'temperature', name: 'Temperature' },
        { label: 'rain_accumulation', name: 'Rain' },
        { label: 'snow_accumulation', name: 'Snow' },
        { label: 'wind_speed', name: 'Wind Speed' },
        { label: 'wind_gust', name: 'Wind Gusts' },
    ];

    if (props.showLightningProbability) {
        weatherTabs = [...weatherTabs, { label: 'lightning_probability', name: 'Probability of Lightning' }];
    }

    let ratingTabs: { label: string, name: string }[] = [
        { label: 'road', name: 'Road' },
        { label: 'disruption', name: 'Disruption' },
        { label: 'flood', name: 'Flood' },
        { label: 'power', name: 'Power' },
        { label: 'life_property', name: 'Life & Property' },
    ];

    if (showWildfireIndices) {
        ratingTabs.push({ label: 'wildfire_spread', name: 'Wildfire Spread' });
        if (!isNowcastGraph) {
            ratingTabs.push({ label: 'wildfire_conditions', name: 'Wildfire Conditions' });
        }
    }

    const createWeatherDatasets = () => {
        if (weatherConditions === undefined) {
            return;
        }

        let datasets: GraphDataset<TimeSeriesDataPoint>[] = [];

        let temperatureDataPoints: TimeSeriesDataPoint[] = [];
        let rainfallDataPoints: TimeSeriesDataPoint[] = [];
        let rainAccumulationDataPoints: TimeSeriesDataPoint[] = [];
        let snowfallDataPoints: TimeSeriesDataPoint[] = [];
        let snowAccumulationDataPoints: TimeSeriesDataPoint[] = [];
        let windSpeedDataPoints: TimeSeriesDataPoint[] = [];
        let windGustDataPoints: TimeSeriesDataPoint[] = [];
        let lightningProbabilityDataPoints: TimeSeriesDataPoint[] = [];

        weatherConditions?.forEach(hour => {
            temperatureDataPoints.push({ time: hour.time, value: hour.temperature });
            rainfallDataPoints.push({ time: hour.time, value: hour.rainfall ?? 0 });
            rainAccumulationDataPoints.push({ time: hour.time, value: hour.rainAccumulation ?? 0 });
            snowfallDataPoints.push({ time: hour.time, value: hour.snowfall ?? 0 });
            snowAccumulationDataPoints.push({ time: hour.time, value: hour.snowAccumulation ?? 0 });
            windSpeedDataPoints.push({ time: hour.time, value: hour.windSpeed ?? 0 });
            windGustDataPoints.push({ time: hour.time, value: hour.windGust ?? 0 });
            lightningProbabilityDataPoints.push({ time: hour.time, value: hour.lightningProbability ?? 0 });
        });

        datasets.push({
            label: "temperature",
            allData: temperatureDataPoints as TimeSeriesDataPoint[],
            sections: [{ data: temperatureDataPoints as TimeSeriesDataPoint[], color: "#0093ff", label: "Temperature" }],
            keyTransform: (hour: TimeSeriesDataPoint) => hour.time.getTime(),
            valueTransform: (hour: TimeSeriesDataPoint) => hour.value,
            keyDisplayTransform: (hour: TimeSeriesDataPoint) => `${moment(hour.time).tz(timezone!).format('hh:mm A z')}`,
            valueDisplayTransform: (hour: TimeSeriesDataPoint) => `${hour.value.toFixed(1)}°`,
            valueSuffix: "° ",
            rangeMode: 'normal',
            maxYRange: [temperatureDataPoints.length === 0 ? 10 : Math.ceil(temperatureDataPoints.reduce((prev, current) => (prev && prev.value > current.value) ? prev : current).value) + 5],
            type: 'scatter',
            colorFunction: function (point: HourRatingsData) {
                const temperatureBlurbs = blurbs['temperature'];
                if (temperatureBlurbs) {
                    const index = Math.max(temperatureBlurbs.findIndex((blurb: any) => point.value <= blurb.value), 0);
                    const color = temperatureBlurbs[index].color;
                    // remove any alpha at the end of the color
                    return color.substring(0, 7);
                }
                return 'white';
            },
            timezone,
        });

        datasets.push({
            label: "rain_accumulation",
            allData: rainAccumulationDataPoints as TimeSeriesDataPoint[],
            sections: [{ data: rainAccumulationDataPoints as TimeSeriesDataPoint[], color: "#0093ff", label: "Future Rain Accumulation" }, { data: rainfallDataPoints as TimeSeriesDataPoint[], color: "#0093ff", label: "Hourly Rainfall Rate", suffix: " in/hr" }],
            keyTransform: (hour: TimeSeriesDataPoint) => hour.time.getTime(),
            valueTransform: (hour: TimeSeriesDataPoint) => hour.value,
            keyDisplayTransform: (hour: TimeSeriesDataPoint) => `${moment(hour.time).tz(timezone!).format('hh:mm A z')}`,
            valueDisplayTransform: (hour: TimeSeriesDataPoint) => `${hour.value.toFixed(2)} in`,
            valueSuffix: " in",
            rangeMode: 'nonnegative',
            maxYRange: [rainAccumulationDataPoints.length === 0 ? 1 : Math.ceil(rainAccumulationDataPoints.reduce((prev, current) => (prev && prev.value > current.value) ? prev : current).value) + 1, rainfallDataPoints.length === 0 ? 1 : Math.ceil(rainfallDataPoints.reduce((prev, current) => (prev && prev.value > current.value) ? prev : current).value) + 1],
            type: 'scatter',
            colorFunction: function (point: HourRatingsData) {
                const rainAccumulationBlurbs = blurbs['rain_accumulation'];
                if (rainAccumulationBlurbs) {
                    const index = Math.max(rainAccumulationBlurbs.findIndex((blurb) => point.value <= blurb.value), 0);
                    const color = rainAccumulationBlurbs[index].color;
                    // remove any alpha at the end of the color
                    return color.substring(0, 7);
                }
                return 'white';
            },
            timezone,
        });

        datasets.push({
            label: "snow_accumulation",
            allData: snowAccumulationDataPoints as TimeSeriesDataPoint[],
            sections: [{ data: snowAccumulationDataPoints as TimeSeriesDataPoint[], color: "#0093ff", label: "Snow Accumulation" }, { data: snowfallDataPoints as TimeSeriesDataPoint[], color: "#0093ff", label: "Hourly Snowfall Rate", suffix: " in/hr" }],
            keyTransform: (hour: TimeSeriesDataPoint) => hour.time.getTime(),
            valueTransform: (hour: TimeSeriesDataPoint) => hour.value,
            keyDisplayTransform: (hour: TimeSeriesDataPoint) => `${moment(hour.time).tz(timezone!).format('hh:mm A z')}`,
            valueDisplayTransform: (hour: TimeSeriesDataPoint) => `${hour.value.toFixed(1)} in`,
            valueSuffix: " in",
            rangeMode: 'nonnegative',
            maxYRange: [snowAccumulationDataPoints.length === 0 ? 1 : Math.ceil(snowAccumulationDataPoints.reduce((prev, current) => (prev && prev.value > current.value) ? prev : current).value) + 1],
            type: 'scatter',
            colorFunction: function (point: HourRatingsData) {
                const snowAccumulationBlurbs = blurbs['snow_accumulation'];
                if (snowAccumulationBlurbs) {
                    const index = Math.max(snowAccumulationBlurbs.findIndex((blurb) => point.value <= blurb.value), 0);
                    const color = snowAccumulationBlurbs[index].color;
                    // remove any alpha at the end of the color
                    return color.substring(0, 7);
                }
                return 'white';
            },
            timezone,
        });

        datasets.push({
            label: "wind_speed",
            allData: windSpeedDataPoints as TimeSeriesDataPoint[],
            sections: [{ data: windSpeedDataPoints as TimeSeriesDataPoint[], color: "#0093ff", label: "Wind Speed" }],
            keyTransform: (hour: TimeSeriesDataPoint) => hour.time.getTime(),
            valueTransform: (hour: TimeSeriesDataPoint) => hour.value,
            keyDisplayTransform: (hour: TimeSeriesDataPoint) => `${moment(hour.time).tz(timezone!).format('hh:mm A z')}`,
            valueDisplayTransform: (hour: TimeSeriesDataPoint) => `${hour.value.toFixed(1)} mph`,
            valueSuffix: " mph",
            rangeMode: 'nonnegative',
            maxYRange: [windSpeedDataPoints.length === 0 ? 10 : Math.ceil(windSpeedDataPoints.reduce((prev, current) => (prev && prev.value > current.value) ? prev : current).value) + 5],
            type: 'scatter',
            colorFunction: function (point: HourRatingsData) {
                const windSpeedBlurbs = blurbs['wind_speed'];
                if (windSpeedBlurbs) {
                    const index = Math.max(windSpeedBlurbs.findIndex((blurb) => point.value <= blurb.value), 0);
                    const color = windSpeedBlurbs[index].color;
                    // remove any alpha at the end of the color
                    return color.substring(0, 7);
                }
                return 'white';
            },
            timezone,
        });

        datasets.push({
            label: "wind_gust",
            allData: windGustDataPoints as TimeSeriesDataPoint[],
            sections: [{ data: windGustDataPoints as TimeSeriesDataPoint[], color: "#0093ff", label: "Wind Gusts" }],
            keyTransform: (hour: TimeSeriesDataPoint) => hour.time.getTime(),
            valueTransform: (hour: TimeSeriesDataPoint) => hour.value,
            keyDisplayTransform: (hour: TimeSeriesDataPoint) => `${moment(hour.time).tz(timezone!).format('hh:mm A z')}`,
            valueDisplayTransform: (hour: TimeSeriesDataPoint) => `${hour.value.toFixed(1)} mph`,
            valueSuffix: " mph",
            rangeMode: 'nonnegative',
            maxYRange: [windGustDataPoints.length === 0 ? 10 : Math.ceil(windGustDataPoints.reduce((prev, current) => (prev && prev.value > current.value) ? prev : current).value) + 5],
            type: 'scatter',
            colorFunction: function (point: HourRatingsData) {
                const windGustBlurbs = blurbs['wind_gust'];
                if (windGustBlurbs) {
                    const index = Math.max(windGustBlurbs.findIndex((blurb) => point.value <= blurb.value), 0);
                    const color = windGustBlurbs[index].color;
                    // remove any alpha at the end of the color
                    return color.substring(0, 7);
                }
                return 'white';
            },
            timezone,
        });

        datasets.push({
            label: "lightning_probability",
            allData: lightningProbabilityDataPoints as TimeSeriesDataPoint[],
            sections: [{ data: lightningProbabilityDataPoints as TimeSeriesDataPoint[], color: "#0093ff", label: "Probability of Lightning" }],
            keyTransform: (hour: TimeSeriesDataPoint) => hour.time.getTime(),
            valueTransform: (hour: TimeSeriesDataPoint) => hour.value * 100,
            keyDisplayTransform: (hour: TimeSeriesDataPoint) => `${moment(hour.time).tz(timezone!).format('hh:mm A z')}`,
            valueDisplayTransform: (hour: TimeSeriesDataPoint) => `${(hour.value * 100).toFixed(1)} %`,
            valueSuffix: " %",
            rangeMode: 'nonnegative',
            maxYRange: [100],
            type: 'scatter',
            colorFunction: function (point: HourRatingsData) {
                const value = point.value;
                if (value >= 0.8) return colorForImpactLevel(ImpactLevel.Extreme);
                if (value >= 0.6 && value < 0.8) return colorForImpactLevel(ImpactLevel.High);
                if (value >= 0.3 && value < 0.6) return colorForImpactLevel(ImpactLevel.Moderate);
                if (value >= 0.1 && value < 0.3) return colorForImpactLevel(ImpactLevel.Low);
                if (value === undefined) return colorForImpactLevel(ImpactLevel.Unknown);
                return 'white';
            },
            timezone,
        });

        setWeatherDatasets(datasets);
    };

    const createRatingDatasets = () => {
        if (ratings === undefined) {
            return;
        }

        let datasets: GraphDataset<HourRatingsData>[] = [];

        let now = new Date();

        relevantRatingKeys(showDisruptionIndex, showWildfireIndices).forEach(ratingKey => {
            if (ratings[ratingKey] === undefined) return;
            let data = props.zoomDisabled ? ratings[ratingKey] : ratings[ratingKey].filter((rating: HourRatingsData) => rating.time >= coerceDateToHour(now));

            let dataset: GraphDataset<HourRatingsData> = {
                label: ratingKey,
                allData: data,
                sections: [{ data: data, color: "#0093ff", label: formatRatingKey(ratingKey) }],
                keyTransform: (hour: HourRatingsData) => hour.time.getTime(),
                valueTransform: (hour: HourRatingsData) => hour.value || 0,
                keyDisplayTransform: (hour: HourRatingsData) => `${moment(hour.time).tz(timezone!).format('hh:mm A z')}`,
                valueDisplayTransform: (hour: HourRatingsData) => {
                    let dateString = hour.time.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', timeZone: timezone });
                    let timeString = `${moment(hour.time).tz(timezone!).format('hh:mm A z')}`;
                    return `${dateString}<br>${timeString}<br>Rating: ${hour.value}`;
                },
                valueSuffix: "",
                rangeMode: 'nonnegative',
                maxYRange: [10.5],
                type: 'scatter',
                colorFunction: function (point: HourRatingsData) {
                    const ratingsBlurbs = blurbs[ratingKey];
                    if (ratingsBlurbs) {
                        const flooredValue = Math.max(Math.floor(point.value), 0);
                        const color = ratingsBlurbs[flooredValue].color;
                        // remove any alpha at the end of the color
                        return color.substring(0, 7);
                    }
                    return 'white';
                },
                timezone,
            };

            datasets.push(dataset);
        });

        setRatingDatasets(datasets);
    };

    React.useEffect(() => {
        createWeatherDatasets();
    }, [weatherConditions, blurbs]);

    React.useEffect(() => {
        createRatingDatasets();
    }, [ratings, blurbs]);

    const getSelectedDataset = () => {
        if (selectedGraphView === 'weather' && weatherDatasets !== undefined) {
            return weatherDatasets.find(dataset => dataset.label === selectedWeatherProperty)!;
        }
        if (selectedGraphView === 'rating' && ratingDatasets !== undefined) {
            return ratingDatasets.find(dataset => dataset.label === selectedRatingKey)!;
        }
        return undefined;
    };

    let selectedDataset: GraphDataset<TimeSeriesDataPoint> | undefined = getSelectedDataset();
    let hasData = selectedDataset !== undefined && selectedDataset.allData.length > 0;

    const onGraphClick = (event: Plotly.PlotMouseEvent) => {
        if (event.points.length === 0) {
            return;
        }

        let point = event.points[0];
        let timestamp = point.x as number;
        let date = new Date(timestamp);

        onValueClicked(date);
    };

    const collapsedHeader = (
        <div className={"collapsed-title"}>
            <div className={"header-title-graph-type"}>Time Series</div>
            <div className={"header-title-for"}>for</div>
            <div className={"header-title-graph-location"}>{selectedCityState.selectedCity?.name || 'Unknown Location'}</div>
            <div style={{ flex: '1 1 0' }} />
        </div>
    );


    let timezoneAndOffset = '';
    if (weatherConditions && weatherConditions[0] && timezone) {
        timezoneAndOffset = moment(weatherConditions[0].time).tz(timezone).format("(z, Z)").replace(' ', ' UTC');
    }

    const graphTitle = (
        <div className={"graph-title"}>
            {!isNowcastGraph && <React.Fragment>
                <div className={"header-title-graph-type"}>{selectedGraphView === 'weather' ? `${weatherTabs.find(tab => tab.label === selectedWeatherProperty)?.name} Forecast`
                    : `${ratingTabs.find(tab => tab.label === selectedRatingKey)?.name} Impact`}</div>
                <div className={"header-title-for"}>for</div>
                <div className={"header-title-graph-location"}>{selectedCityState.selectedCity?.name ? `${selectedCityState.selectedCity?.name} ${timezoneAndOffset}` : 'Unknown Location'}</div>
            </React.Fragment>}
            {selectedWeatherProperty === 'lightning_probability' && <div className={"header-title-for"}>{`within 4 miles over the next hour`}</div>}
            <div style={{ flex: '1 1 0' }} />
        </div>
    );

    let expandedHeader = (
        <>
            {isNowcastGraph &&
                <Select
                    value={selectedGraphView}
                    variant={'outlined'}
                    style={{ margin: "0px 5px", width: 200, height: 40 }}
                    onChange={(event) => setSelectedGraphView && setSelectedGraphView(event.target.value)}
                >
                    {graphViews?.map(graphView => (
                        <MenuItem value={graphView.key}>{graphView.title}</MenuItem>
                    ))}
                </Select>}
            <Tabs
                className="TimeSeriesTabs"
                value={selectedGraphView === 'weather' ? weatherTabs.findIndex(tab => tab.label === selectedWeatherProperty) : ratingTabs.findIndex(tab => tab.label === selectedRatingKey)}
                onChange={(eventevent, newValue) => selectedGraphView === 'weather' ? onWeatherPropertyChanged(weatherTabs[newValue].label as RatingKey) : onRatingKeySelected(ratingTabs[newValue].label as RatingKey)}
                indicatorColor={'primary'}
                textColor={'primary'}
                variant="scrollable"
                scrollButtons="auto"
                style={{ height: 50 }}
            >
                {selectedGraphView === 'weather' ? weatherTabs.map(tab => <Tab label={tab.name} />) : ratingTabs.map(tab => <Tab label={tab.name} />)}
            </Tabs>
            <div style={{ flex: '1 1 0' }} />
            {!props.zoomDisabled && (selectedGraphView !== 'rating' || selectedRatingKey !== 'wildfire_spread') && <div className={"zoom-container"} key={"zoom"} style={{ minWidth: 100 }}>
                <div className="day-range-slider">
                    <Slider
                        className={'slider'}
                        min={1}
                        max={7}
                        step={1}
                        defaultValue={4}
                        size="small"
                        value={zoom}
                        onChange={(event: Event, newValue: number | number[]) => setZoom(newValue as number)}
                    />
                    <div className="day-range-labels">
                        <span>+1d</span>
                        <span>{selectedRatingKey === 'wildfire_conditions' ? '+6d' : '+7d'}</span>
                    </div>
                </div>
            </div>}
            {isNowcastGraph &&
                <>
                    <Tooltip title={`Enable ${formatRatingKey((selectedGraphView === 'weather' ? selectedWeatherProperty : selectedRatingKey) as string)} Map Layer`}>
                        <IconButton
                            color={'primary'}
                            onClick={() => setTilingLayer && setTilingLayer((selectedGraphView === 'weather' ? selectedWeatherProperty : selectedRatingKey) as string)}
                            style={{ height: 40 }}
                        >
                            <MapOutlined />
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        color={'primary'}
                        onClick={() => onCloseClicked && onCloseClicked()}
                        style={{ height: 40 }}
                    >
                        <Close />
                    </IconButton>
                </>}
        </>
    );

    return (
        <Paper className={isNowcastGraph ? "AssetGraphComponent" : "TimeSeriesGraphComponent"} elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: isNowcastGraph ? '0px 11px 11px 0px' : 11, ...props.extraStyles }}>
            <header style={{ padding: collapsed ? '8px 24px' : isNowcastGraph ? '12px 25px 0px 5px' : '12px 24px 0', display: 'flex', flexDirection: 'row' }}>
                {collapsed ? collapsedHeader : expandedHeader}
                {!isNowcastGraph && <IconButton
                    color={'primary'}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{ height: 40 }}
                >
                    {collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>}
            </header>

            {!collapsed && <div style={{ width: '100%', height: 1, backgroundColor: '#FFFFFF' }} />}

            {!collapsed && !hasData && (
                <div className={"flex-center"} style={{ width: '100%', height: isNowcastGraph ? 245 : 325 }}>
                    <img
                        width={24}
                        height={24}
                        src={"/images/loading.gif"}
                        alt={"Loading..."}
                    />
                </div>)
            }

            {!collapsed && hasData && (
                <GraphWidget
                    backgroundColor={cardBackgroundColor}
                    showFullDomain={false}
                    additionalClasses={""}
                    headerChildren={[]}
                    title={(isNowcastGraph && selectedWeatherProperty !== 'lightning_probability') ? undefined : graphTitle}
                    datasets={selectedGraphView === 'weather' ? weatherDatasets : ratingDatasets}
                    zoom={props.zoomDisabled ? 0 : (selectedGraphView === 'rating' && selectedRatingKey === 'wildfire_spread') ? 0 : 1 - (zoom / 7)}
                    tickInterval={props.zoomDisabled ? 'subhour' : 'day'}
                    selectedDataset={selectedDataset}
                    onClick={(event) => onGraphClick(event)}
                    headerStyles={{ backgroundColor: cardBackgroundColor }}
                    bodyStyles={(isNowcastGraph && selectedWeatherProperty !== 'lightning_probability') ? { backgroundColor: cardBackgroundColor, paddingTop: '20px', height: '200px' } : { backgroundColor: cardBackgroundColor }}
                    compactVersion={isNowcastGraph}
                    now={now}
                />
            )
            }
        </Paper>
    );
};
