import * as React from 'react';
import './index.css';
import { locationDataToRouteWaypoint, RouteData, RoutesViewState, RouteWaypoint } from '../../../types/routes';
import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Switch,
    Tab,
    Tabs
} from '@mui/material';
import { BasicLatLng, LocationData, UserState, VehicleType } from '../../../types';
import { defaultRouteAndMarkerMapProps, RouteAndMarkerMap } from '../../shared/RouteAndMarkerMap';
import { RouteEntryForm } from './RouteEntryForm';
import { ResultsInfoComponent } from './ResultsInfo';
import { SlowdownLegendComponent } from './SlowdownLegendComponent';
import { SavedRoutesTable } from "./SavedRoutesTable";
import { cardBackgroundColor } from "../../../constants";
import PortalTour from '../PortalTour';
import { getUserDescriptionOfError } from 'src/types/unmarshal';
import { Config } from 'src/components/shared/useConfig';
import { Spacer } from '../../Public/PricingCalculatorEmbed';
import moment, { Moment } from 'moment';

const TAG = 'ClientRoutesPage';

interface Props extends RoutesViewState {
    user?: UserState;
    savedRoutes: RouteData[];
    selectedCity?: LocationData;

    setSelectedTab: (selectedTab: number) => void;
    setSelectedRoute: (route?: RouteData) => void;
    setOrigin: (origin?: RouteWaypoint) => void;
    setDestination: (destination?: RouteWaypoint) => void;
    setWaypoints: (waypoints?: RouteWaypoint[]) => void;
    setDepartureTime: (departureTime?: Date) => void;
    setAboveTemperatureThreshold: (temperature?: number) => void;
    setBelowTemperatureThreshold: (temperature?: number) => void;
    setVehicleType: (vehicleType?: VehicleType) => void;
    saveRoute: (route: RouteData) => void;
    runRoute: (route: RouteData) => void;
    clearError: () => void;

    loadLocation: (locationId: string) => void;

    center?: BasicLatLng;
    zoomLevel: number;

    onCenterChanged: (center: BasicLatLng) => void;
    onZoomLevelChanged: (zoomLevel: number) => void;
}

export const ClientRoutesPage = (props: Props) => {
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
    const [justSavedRoute, setJustSavedRoute] = React.useState(false);
    const [justRanRoute, setJustRanRoute] = React.useState(false);
    const [runTour, setRunTour] = React.useState(false);
    const hasNoSearchString = window.location.search.length === 0;
    // if we have no search string, then we have "processed" it
    const [processedUrlParams, setProcessedUrlParams] = React.useState(hasNoSearchString);
    const [currentWaypoint, setCurrentWaypoint] = React.useState<RouteWaypoint | undefined>(undefined);
    const [showSavedLocations, setShowSavedLocations] = React.useState(false);

    const { enteredOrigin, enteredDestination, enteredWaypoints, enteredDepartureTime, setOrigin, setDestination, setWaypoints } = props;

    // this tracks the user's input into the departure time field
    // the moment value may be an invalid date so we have an effect check
    // that the date is valid before assigning to our enteredDepartureTime prop
    const [departureTimeMoment, setDepartureTimeMoment] = React.useState<Moment | null>(enteredDepartureTime ? moment(enteredDepartureTime) : null);

    React.useEffect(() => {
        setRunTour(Config.getBoolean(Config.Key.PortalTourComplete) === false);
    }, []);

    React.useEffect(() => {
        const date = departureTimeMoment?.toDate();
        if (date && !isNaN(date.getTime())) {
            props.setDepartureTime(date);
        } else {
            props.setDepartureTime(undefined);
        }
    }, [departureTimeMoment]);

    const dismissDialogAndClearError = () => {
        setErrorDialogOpen(false);
        props.clearError();
    };

    React.useEffect(() => {
        if (currentWaypoint !== undefined) {
            if (!enteredOrigin) {
                setOrigin(currentWaypoint);
            } else if (props.enteredWaypoints !== undefined && props.enteredWaypoints.findIndex(Object.is.bind(null, undefined)) !== -1) {
                const updatedWaypoints = [...props.enteredWaypoints];
                updatedWaypoints[props.enteredWaypoints.findIndex(Object.is.bind(null, undefined))] = currentWaypoint;
                setWaypoints(updatedWaypoints);
            } else if (!enteredDestination) {
                setDestination(currentWaypoint);
            }
        }
    }, [currentWaypoint]);

    // valid if no waypoints or all waypoints are defined
    const validWaypoints: boolean = enteredWaypoints === undefined || enteredWaypoints.filter(wp => wp === undefined).length === 0;
    const hasValidInput = enteredOrigin !== undefined && enteredDestination !== undefined && validWaypoints && enteredDepartureTime !== undefined && !isNaN(enteredDepartureTime.getTime());
    const enteredRoute: RouteData | undefined = hasValidInput
        ? { origin: enteredOrigin!, destination: enteredDestination!, waypoints: enteredWaypoints, departureTime: enteredDepartureTime!, aboveTemperatureThreshold: props.enteredAboveTemperatureThreshold, belowTemperatureThreshold: props.enteredBelowTemperatureThreshold, vehicleData: { id: 'portal_temp_vehicle', type: props.enteredVehicleType }, status: 'unknown' }
        : undefined;

    React.useEffect(() => {
        setJustRanRoute(false);
        setJustSavedRoute(false);
    }, [enteredOrigin, enteredDestination, enteredWaypoints, enteredDepartureTime, props.enteredAboveTemperatureThreshold, props.enteredBelowTemperatureThreshold, props.enteredVehicleType]);

    React.useEffect(() => {
        // open error dialog when route run error appears
        if (props.routesTabError !== undefined) {
            setJustSavedRoute(false);
            setErrorDialogOpen(true);
        }
    }, [props.routesTabError]);

    // process url params
    React.useEffect(() => {
        // TODO: do we want to error if any of these are invalid or just ignore
        if (processedUrlParams) return;
        if (!props.user) return;

        const urlParams = new URLSearchParams(window.location.search);

        const originLocationId = urlParams.get('origin_location_id');
        const originLatitudeStr = urlParams.get('origin_latitude');
        const originLongitudeStr = urlParams.get('origin_longitude');
        if (originLocationId) {
            // if we don't find the location and we haven't loaded all the locations yet
            // then load this specific location to see if that finds it
            const location = props.user.cities.find(x => x.id?.toString() === originLocationId);
            if (!location && props.user.citiesMetadata.loading) {
                props.loadLocation(originLocationId);
                return;
            }
            if (location) {
                console.log(TAG, 'loading a preset origin from params', location);
                props.setOrigin(locationDataToRouteWaypoint(location));
            }
        } else if (originLatitudeStr && originLongitudeStr) {
            const originLatitude = parseFloat(originLatitudeStr);
            const originLongitude = parseFloat(originLongitudeStr);
            if (!isNaN(originLatitude) && !isNaN(originLongitude)) {
                console.log(TAG, `loading a preset origin from params: ${originLatitude}, ${originLongitude}`);
                props.setOrigin({
                    label: `${originLatitude.toFixed(5)}, ${originLongitude.toFixed(5)}`,
                    latitude: originLatitude,
                    longitude: originLongitude,
                });
            }
        }
        setProcessedUrlParams(true);
    }, [props.user?.cities]);

    if (!props.user || !props.user.token) return <div />;

    const displayedRoute = props.selectedTab === 1 ? props.selectedRoute : enteredRoute;

    let displayedRouteResults = props.cachedRouteResults.find(route => route.id === displayedRoute?.id)?.latestRouteResults;
    if (displayedRouteResults === undefined && displayedRoute?.latestRouteResults !== undefined) {
        // if we haven't run a route, try fetching its prepopulated data first
        displayedRouteResults = displayedRoute.latestRouteResults;
    }

    let resultsInfoComponent = displayedRoute && (displayedRouteResults !== undefined || displayedRoute.latestRouteResultError) && justRanRoute &&
        <ResultsInfoComponent route={displayedRoute} results={displayedRouteResults?.[0]} />;

    const handleClickRun = () => {
        setJustRanRoute(true);
        props.runRoute(displayedRoute!);
    };

    let runButtonComponent = (
        <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => handleClickRun()}
            disabled={displayedRoute === undefined}

            // for some reason href is required to make the primary color always show, not only on hover
            href={`javascript:;`}
        >
            RUN
        </Button>
    );

    const handleClickSave = () => {
        setJustSavedRoute(true);
        props.saveRoute(enteredRoute!);
    };

    let saveButtonComponent: JSX.Element = (
        <Button
            variant={'outlined'}
            onClick={() => handleClickSave()}
            disabled={enteredRoute === undefined || justSavedRoute === true || props.routeBeingSaved !== undefined}

            // for some reason href is required to make the primary color always show, not only on hover
            href={`javascript:;`}
        >
            SAVE
        </Button>
    );

    const handleClickClearAll = () => {
        setJustRanRoute(false);
        setJustSavedRoute(false);

        props.setOrigin(undefined);
        props.setDestination(undefined);
        props.setWaypoints(undefined);
        props.setDepartureTime(undefined);
        setDepartureTimeMoment(null);
        props.setAboveTemperatureThreshold(undefined);
        props.setBelowTemperatureThreshold(undefined);
    };

    let clearAllButtonComponent: JSX.Element = (
        <Button
            variant={'outlined'}
            onClick={() => handleClickClearAll()}
            disabled={enteredOrigin === undefined && enteredDestination === undefined && props.enteredWaypoints === undefined &&
                props.enteredDepartureTime === undefined && props.enteredAboveTemperatureThreshold === undefined && props.enteredBelowTemperatureThreshold === undefined && props.enteredVehicleType === undefined}

            // for some reason href is required to make the primary color always show, not only on hover
            href={`javascript:;`}
        >
            CLEAR ALL
        </Button>
    );

    let savedComponent: JSX.Element = (
        <Button
            variant={'contained'}
            style={{ background: '#378E28' }}
        >
            SAVED
            <span role={'img'} aria-label={'checkmark'}>✅</span>
        </Button>
    );

    const { errorTitle, errorMessage } = getUserDescriptionOfError(props.routesTabError);

    const isSavingRoute = props.routeBeingSaved !== undefined;

    const routeEntryForm = <RouteEntryForm
        origin={props.enteredOrigin}
        destination={props.enteredDestination}
        waypoints={props.enteredWaypoints}
        departureTime={departureTimeMoment}
        token={props.user.token}
        userId={props.user.id}
        vehicleType={props.enteredVehicleType}
        setOrigin={(origin) => props.setOrigin(origin)}
        setDestination={(destination) => props.setDestination(destination)}
        setWaypoints={(waypoints) => props.setWaypoints(waypoints)}
        setDepartureTime={(departureTime) => setDepartureTimeMoment(departureTime)}
        setAboveTemperatureThreshold={(temperature) => props.setAboveTemperatureThreshold(temperature)}
        setBelowTemperatureThreshold={(temperature) => props.setBelowTemperatureThreshold(temperature)}
        setVehicleType={(vehicleType) => props.setVehicleType(vehicleType)}
    />;

    let routeOption = (displayedRoute && displayedRouteResults && (justRanRoute || justSavedRoute)) ? `${displayedRoute?.id} Option 0` : '';
    let polylines = (displayedRoute && displayedRouteResults && (justRanRoute || justSavedRoute)) ? displayedRouteResults[0].slowdownPolylines : undefined;

    let buttonRowComponent: JSX.Element | undefined = undefined;
    if (props.selectedTab === 0) {
        buttonRowComponent = (<div style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 12,
            alignItems: 'center',
        }}>
            {runButtonComponent}
            &nbsp;&nbsp;
            {(!justSavedRoute || justSavedRoute && isSavingRoute) && saveButtonComponent}
            {justSavedRoute && !isSavingRoute && savedComponent}
            &nbsp;&nbsp;
            {(props.currentlyRunningRoute !== undefined || isSavingRoute) && <CircularProgress size={25} />}
            <Spacer stretch={true} />
            {clearAllButtonComponent}

        </div>);
    }

    if (!processedUrlParams) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <div className={'route-tab-container'}>
            <RouteAndMarkerMap
                {...defaultRouteAndMarkerMapProps}
                userId={props.user.id}
                selectedCity={undefined}

                selectedRouteOption={routeOption}
                origin={props.enteredOrigin ?? displayedRoute?.origin}
                destination={props.enteredDestination ?? displayedRoute?.destination}
                waypoints={(props.enteredWaypoints ?? displayedRoute?.waypoints)}
                padding={{ left: 545 }}
                slowdownPolylines={polylines}
                isLocationsVisible={showSavedLocations}
                savedCities={props.user.cities}
                citiesLoading={props.user.citiesMetadata.loading}
                initialCenter={props.center}
                // instead of using initialBounds, the props for center/zoom take care
                // of the default here
                zoomLevel={props.zoomLevel}
                onCitySelected={(location: LocationData) => {
                    const waypoint: RouteWaypoint = locationDataToRouteWaypoint(location)!;
                    setCurrentWaypoint(waypoint);
                }}
                onCenterChanged={(center) => props.onCenterChanged(center)}
                onZoomLevelChanged={(zoomLevel) => props.onZoomLevelChanged(zoomLevel)}
                onMapClicked={(coord: google.maps.LatLng) => {
                    const waypoint: RouteWaypoint = {
                        label: `${coord.lat().toFixed(5)}, ${coord.lng().toFixed(5)}`,
                        latitude: coord.lat(),
                        longitude: coord.lng(),
                    };
                    setCurrentWaypoint(waypoint);
                }}
            />
            <Card className={'route-header'} style={{ maxHeight: 'calc(100vh - 80px)', overflow: 'auto', backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11 }}>
                <Tabs style={{ marginBottom: '12px', }} value={props.selectedTab} onChange={(event, newValue) => props.setSelectedTab(newValue)} centered>
                    <Tab label={"Input Route"} id={'tab-input-route'} />
                    <Tab label={"Upcoming Routes"} id={'tab-saved-routes'} />
                </Tabs>
                {props.selectedTab === 0 && routeEntryForm}
                {props.selectedTab === 1 && <SavedRoutesTable
                    routes={props.savedRoutes}
                    runButton={runButtonComponent}
                    selectedRoute={props.selectedRoute}
                    setSelectedRoute={route => props.setSelectedRoute(route)}
                />}

                {buttonRowComponent}

                {resultsInfoComponent}
            </Card>

            <FormControlLabel
                className={'switch'}
                style={{ position: 'absolute', left: '536px', top: '9px', backgroundColor: cardBackgroundColor, padding: '8px', borderRadius: '11px' }}
                control={<Switch
                    checked={showSavedLocations}
                    onChange={event => setShowSavedLocations(event.target.checked)}
                />}
                label="Show saved locations?"
                labelPlacement="start"
            />

            {displayedRouteResults && <SlowdownLegendComponent />}

            <Dialog
                open={errorDialogOpen}
                onClose={() => dismissDialogAndClearError()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{errorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dismissDialogAndClearError()} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <PortalTour run={runTour} portalTab="ROUTES" />
        </div>
    );
};
