import { StoreState, StormData } from '../../types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { StormsViewerPage } from '../../components/Internal/StormsViewer';
import { ReceiveEventsData } from '../../actions/Weather';
import { SelectStorm } from '../../actions/ImpactView';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        token: state.user.portalToken,
        storms: state.weather.storms || [],
        selectedStorm: state.impactView.selectedStorm,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        onStormsJsonLoaded: (json: JSON) => {
            dispatch(ReceiveEventsData({
                eventsData: json, receivedAt: Date.now(),
            }));
        },
        onStormSelected: (storm: StormData | undefined) => {
            if (storm) {
                dispatch(SelectStorm({ storm, }));
            }
        },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StormsViewerPage));
