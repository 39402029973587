import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { initiateLoginWithData } from '../../../actions/User';
import { GeotabAuthPage } from 'src/components/CustomDashboards/Geotab';
import { StoreState } from 'src/types';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        userData: state.user,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        initiateLogin: (loginData: any) => dispatch(initiateLoginWithData(loginData)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeotabAuthPage));
