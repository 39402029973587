import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, Typography } from "@mui/material";
import { cardBackgroundColor } from "../../../constants";
import { DashboardOverlay } from '../Dashboard';
import { RoadStatusLegendComponent } from '../Dashboard/RoadStatusLegendComponent';

interface Props {
    selectedOverlay: DashboardOverlay;
    zoomLevel: number;
    show511InPortal?: boolean;
    hidePremiumLayers?: boolean;

    onOverlayChanged(overlay: DashboardOverlay): void;
}

const DEFAULT_PROPS = {
    show511InPortal: true,
};// satisfies Partial<Props>;
// ^ can add when we upgrade to typescript 4.9+

export const TrafficLegend = (props: Props) => {
    const { selectedOverlay, zoomLevel, show511InPortal, onOverlayChanged } = { ...DEFAULT_PROPS, ...props };
    const [showRoadConditionsUpsell, setShowRoadConditionsUpsell] = React.useState<boolean>(false);

    return (
        <div className='traffic-legend' style={{ backgroundColor: cardBackgroundColor }}>
            <header className='traffic-legend-header'>Traffic</header>
            <div className='traffic-legend-select-wrapper' style={{ "padding": "15px 25px", "display": "flex", "flexDirection": "row" }}>
                <Select
                    value={selectedOverlay}
                    variant={'outlined'}
                    style={{ width: 235, height: 40 }}
                    onChange={(event) => (!show511InPortal && event.target.value as DashboardOverlay !== 'traffic') ? setShowRoadConditionsUpsell(true) : onOverlayChanged(event.target.value as DashboardOverlay)}
                >
                    <MenuItem value={'traffic'}>Traffic Flow</MenuItem>
                    {!props.hidePremiumLayers && <React.Fragment>
                        <MenuItem value={'traffic-incidents'}>Traffic Incidents {!show511InPortal && <img style={{ marginLeft: 5 }} alt={'premium-feature'} src={'/images/premium_feature_gem.svg'} />}</MenuItem>
                        <MenuItem value={'traffic-road-status'}>Driving Conditions {!show511InPortal && <img style={{ marginLeft: 5 }} alt={'premium-feature'} src={'/images/premium_feature_gem.svg'} />}</MenuItem>
                        <MenuItem value={'traffic-road-work'}>Road Work {!show511InPortal && <img style={{ marginLeft: 5 }} alt={'premium-feature'} src={'/images/premium_feature_gem.svg'} />}</MenuItem>
                        <MenuItem value={'traffic-road-closures'}>Road Closures {!show511InPortal && <img style={{ marginLeft: 5 }} alt={'premium-feature'} src={'/images/premium_feature_gem.svg'} />}</MenuItem>
                        <MenuItem value={'traffic-truck-warnings'}>Trucking Warnings {!show511InPortal && <img style={{ marginLeft: 5 }} alt={'premium-feature'} src={'/images/premium_feature_gem.svg'} />}</MenuItem>
                    </React.Fragment>}
                </Select>
                {selectedOverlay === 'traffic' && zoomLevel && zoomLevel < 5 && <Typography style={{ "margin": "10px 0px 0px 25px" }} color={'textSecondary'} variant={'body1'}>
                    {"May need to zoom in further to see traffic."}
                </Typography>}
                {selectedOverlay === 'traffic-incidents' && zoomLevel && zoomLevel < 7 && <Typography style={{ "margin": "10px 0px 0px 25px" }} color={'textSecondary'} variant={'body1'}>
                    {"May need to zoom in further to see incidents."}
                </Typography>}
            </div>
            <div className='traffic-legend-image' style={{ "padding": "5px 25px 15px 25px" }}>
                {selectedOverlay === 'traffic' && <img style={{ "width": "100%", maxWidth: '450px' }} alt={'Flow'} src={`/legends/Traffic_Flow.svg`} />}
                {selectedOverlay === 'traffic-incidents' && <img style={{ "width": "100%", maxWidth: '450px' }} alt={'Severity'} src={`/legends/Traffic_Incident_Severity.svg`} />}
                {selectedOverlay === 'traffic-road-status' && <RoadStatusLegendComponent />}
                {/* TODO: Do we need legends for the other road condition types? */}
            </div>
            <Dialog open={showRoadConditionsUpsell}>
                <DialogTitle>
                    New Product: {'511 Road Conditions!'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {'511 Road Conditions help your team monitor real time traffic conditions, road status and road work for improved enterprise resiliency & planning. To learn more and upgrade to this product, contact your account manager.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowRoadConditionsUpsell(false)}>Got it!</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
