import * as React from "react";
import './index.css';
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from "../../shared/RouteAndMarkerMap";
import { Button, Paper, styled } from "@mui/material";
import { cardBackgroundColor } from "src/constants";
import { StormData } from "src/types";
import { CloudUploadOutlined } from "@mui/icons-material";
import { File } from "buffer";
import { EventDescriptionComponent } from "../../Client/Impact/EventDescriptionComponent";
import { MapControlsComponent } from "../../shared/MapControlsComponent";

export interface Props {
    token?: string;
    storms: StormData[];
    selectedStorm?: StormData;

    onStormsJsonLoaded: (json: JSON) => void;
    onStormSelected: (storm: StormData | undefined) => void;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function FileInput(props: { onFileSelected: (file: File) => void }) {
    // const [selectedFile, setSelectedFile] = React.useState(null);
    // const fileInputRef = React.useRef<Input>(null);

    // const handleFileChange = (event) => {
    //     setSelectedFile(event.target.files[0]);
    // };

    // const handleButtonClick = () => {
    //     fileInputRef.current?.click();
    // };

    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadOutlined />}
        >
            Upload files
            <VisuallyHiddenInput
                type="file"
                name="fileInput"
                onChange={(event: any) => props.onFileSelected(event.target.files[0])}
                multiple
            />
        </Button>
    );
}

function roundDownToNearestHour(date: Date) {
    // Create a new Date object to avoid modifying the original
    const roundedDate = new Date(date);

    // Set minutes, seconds, and milliseconds to zero
    roundedDate.setMinutes(0);
    roundedDate.setSeconds(0);
    roundedDate.setMilliseconds(0);

    return roundedDate;
}

function addHours(date: Date, hours: number) {
    return new Date(date.getTime() + (hours * 60 * 60 * 1000));
}

export const StormsViewerPage = (props: Props) => {
    const { token, storms } = props;

    const [paused, setPaused] = React.useState<boolean>(true);
    const [timeIndex, setTimeIndex] = React.useState(0);
    const timerRef = React.useRef<NodeJS.Timeout | undefined>(undefined);
    const startTime = React.useMemo(() => {
        if (storms.length === 0) {
            return roundDownToNearestHour(new Date());
        } else {
            return new Date(Math.min(...storms.map(x => x.startTime.getTime())));
        }
    }, [storms]);
    const times = React.useMemo(() => {
        return new Array(168).fill(0).map((_, i) => addHours(startTime, i));
    }, [startTime]);
    const displayedTime = React.useMemo(() => {
        return times[timeIndex];
    }, [times, timeIndex]);

    const goToPreviousTimeOffset = () => {
        setTimeIndex((i) => Math.max(0, i - 1));
    };

    const goToNextTimeOffset = () => {
        setTimeIndex((i) => Math.min(times.length - 1, i + 1));
    };

    const goToDate = (date: Date) => {
        pause();
        const index = times.findIndex((x) => x.getTime() === date.getTime());
        if (index !== undefined) {
            setTimeIndex(index);
        }
    };

    const resume = () => {
        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
            goToNextTimeOffset();
            resume();
        }, 1000);

        setPaused(false);
    };

    const pause = () => {
        clearTimeout(timerRef.current);
        setPaused(true);
    };

    const rewind = () => {
        pause();
        goToPreviousTimeOffset();
    };

    const fastForward = () => {
        pause();
        goToNextTimeOffset();
    };

    const skipToFirst = () => {
        pause();
        setTimeIndex(0);
    };

    const skipToLast = () => {
        pause();
        setTimeIndex(times.length - 1);
    };

    const urlInputComponent = (
        <div className={'alert-info'}>
            <Paper elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                padding: 15,
                borderRadius: 11,
                position: 'absolute',
                left: '12px',
                top: '12px',
                width: '300px',
            }}>
                <FileInput
                    onFileSelected={(file: File) => {
                        file.text().then((jsonString) => {
                            const featureCollectionJSON = JSON.parse(jsonString);
                            props.onStormsJsonLoaded(featureCollectionJSON);
                        });
                    }}
                />
            </Paper>
        </div>
    );

    const currentStorms = React.useMemo(() => {
        return storms.filter(storm => storm.startTime <= displayedTime && storm.endTime >= displayedTime);
    }, [storms, displayedTime]);

    return (
        <div className={"StormsViewer"}>
            <RouteAndMarkerMap
                {...defaultRouteAndMarkerMapProps}

                initialCenter={{ lat: 39.82, lng: -98.57 }}
                padding={{ bottom: 300 }}
                zoomLevel={8}
                // enum that's in the types says it should be 3 to get it to be bottom right
                // but that gives top right, 9 gets it to bottom right
                zoomControlOptions={{ position: 9.0 }}

                portalToken={token}

                storms={currentStorms}
                isStormsVisible={true}
                onStormSelected={(storm) => props.onStormSelected(storm)}
                selectedStorm={props.selectedStorm}

                isLocationsVisible={false}
                savedCities={[]}

                onCitySelected={(city) => { }}
                onCenterChanged={(_center) => { }}
                onZoomLevelChanged={(zoomLevel) => { }}
                onNotificationsAvailable={(newNotifications) => { }}
                onTrackingModeToggled={(trackingMode) => { }}
                onPolylineSelected={(polyline) => { }}
            />

            {urlInputComponent}

            <div className={"EventDescriptionComponent-Container"}>
                <EventDescriptionComponent
                    allowClose={true}

                    selectedStorm={props.selectedStorm}

                    isStormsVisible={true}
                />
            </div>

            <div style={{ position: 'absolute', bottom: '30px', left: '10px' }}>
                <MapControlsComponent
                    paused={paused}
                    speedIncrement={1}
                    times={times}
                    displayedTime={displayedTime}
                    loading={false}
                    horizontalLayout={true}

                    onPause={() => pause()}
                    onResume={() => resume()}
                    onFastForward={() => fastForward()}
                    onRewind={() => rewind()}
                    onSkipToFirst={() => skipToFirst()}
                    onSkipToLast={() => skipToLast()}
                    onCycleSpeedIncrement={() => { }}
                    setSelectedDate={(date: Date) => goToDate(date)}
                />
            </div>
        </div>
    );
};
