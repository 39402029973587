import React from 'react';
import '../Dashboard/TimelineChart.css';
import { AlertData, ImpactTag, UserState, VehicleImpactTag, VehicleTrackingData, WeatherData } from "src/types";
import { Timeframe } from "../Dashboard/data";
import { Button, Chip, IconButton, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material';
import { brightCardBackgroundColor, cardBackgroundColor } from 'src/constants';
import { Close, ForwardToInbox, GpsFixed, Route, South, Visibility } from '@mui/icons-material';
import { darkTagColorForImpactLevel, ImpactLevel, RouteData, vehicleTrackingDataToLocationData, wordForImpactLevel } from '../../../types/routes';
import AlertTimelineView from './AlertTimelineView';
import { fetchWeatherDataOnce } from '../../../actions/Weather';
import { SendAlertData, SendAlertDialog } from '../Dashboard/ImpactedVehiclesTable';
import TimeAgo from 'javascript-time-ago';
import { getAssetName } from '.';
import { snakeCaseToTitleCase } from 'src/util';
import { WiCloud, WiRain, WiRainMix, WiSnow } from 'weather-icons-react';

export const VEHICLE_CHART_VIEWS = [
    { key: 'current_conditions', title: 'Weather Conditions' },
    { key: 'alerts', title: 'NWS Alerts' },
];

const bearingToCompassDirection = (bearing: number | undefined) => {
    if (bearing === undefined)
        return undefined;

    const val = Math.floor((bearing / 22.5) + 0.5) % 16;
    const arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
    return arr[val];
};

export const getSubtitleForVehicle = (vehicle: VehicleTrackingData) => {
    const compassDirection = bearingToCompassDirection(vehicle.bearing);
    const speedText = vehicle.speed?.toFixed(0);
    let subtitle = 'Heading unknown';
    if (compassDirection && speedText && speedText !== '0') {
        subtitle = `Moving ${compassDirection} @ ${speedText} mph`;
    } else if (compassDirection) {
        subtitle = `Facing ${compassDirection}`;
    } else if (speedText && speedText !== '0') {
        subtitle = `Moving @ ${speedText} mph`;
    }
    return subtitle;
};

interface Props {
    userData: UserState;
    selectedVehicle: VehicleTrackingData;
    matchingRoute?: RouteData;
    selectedChartView: string;
    timeframe: Timeframe;

    onCloseClicked?: () => void;
    setSelectedChartView: (view: string) => void;
    setAlerts: (alerts: AlertData[]) => void;
    setSelectedAlert: (alert: AlertData) => void;
    onVehicleMessageRequested: (vehicle: VehicleTrackingData, message: string, urgent: boolean) => void;
    onCenterPressed: (latitude: number, longitude: number) => void;
    onRoutePressed: (vehicle: VehicleTrackingData) => void;

    extraStyles?: React.CSSProperties;
}

const conditionColors: { [key: string]: string } = {
    green: '#01FF77',
    purple: '#B28CFF',
    red: '#FF7063',
    orange: '#FFA163',
    yellow: '#FFEC6F',
    blue: '#00C0FF',
    white: '#FFFFFF',
};

const ConditionCell = (props: {
    title: string,
    icon: JSX.Element,
    text: string,
    textColor?: string,
    subtext?: string,
}) => {
    return (
        <div
            style={{
                flex: '1 1 149px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: 13,
                minWidth: 149,
                aspectRatio: '149 / 143',
                minHeight: 143,
                maxHeight: '100%',
                boxSizing: 'border-box',
                backgroundColor: 'rgba(217, 217, 217, 0.2)',
                paddingTop: 18,
                paddingBottom: props.subtext ? 10 : 22,
                marginLeft: 4,
                marginRight: 5,
            }}
        >
            <Typography
                variant={'subtitle1'}
                style={{
                    color: 'white',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    lineHeight: '21px',
                }}
            >
                {props.title}
            </Typography>

            {props.icon}

            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Typography
                    variant={'subtitle1'}
                    style={{
                        color: props.textColor ?? 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        lineHeight: '16px',
                    }}
                >
                    {props.text}
                </Typography>

                {props.subtext && <Typography
                    variant={'subtitle1'}
                    style={{
                        color: 'white',
                        opacity: 0.5,
                        fontSize: '11px',
                        lineHeight: '13px',
                    }}
                >
                    {props.subtext}
                </Typography>}
            </div>
        </div>
    );
};

const compactButtonStyles: React.CSSProperties = { padding: 0, width: 43, minWidth: 43, maxWidth: 43, boxSizing: 'border-box', height: 38, borderRadius: 8 };
function SendAlertButton(props: { vehicle: VehicleTrackingData; style?: React.CSSProperties; setAlertData: (data: SendAlertData) => void; setShowAlertDialog: (show: boolean) => void; }) {
    const { vehicle } = props;

    const source = vehicle.source;
    // TODO: move this logic to the server and return in /vehicles endpoint
    let issueWithSendingMessage: string | undefined = undefined;
    switch (source) {
        case "geotab":
            break;
        case "samsara":
            break;
        case "platform_science":
            if (vehicle.externalDriverId === undefined) {
                issueWithSendingMessage = "vehicle has no external driver id";
            }
            break;
        case "weatheroptics":
            break;
        case "motive":
            if (vehicle.externalDriverId === undefined) {
                issueWithSendingMessage = "vehicle has no external driver id";
            }
            break;
        default:
            issueWithSendingMessage = "unsupported vehicle source for messaging";
            break;
    }
    const truckId = vehicle.name || vehicle.externalId;
    const impact = vehicle.currentImpact;
    const tooltipTitle = issueWithSendingMessage || "Send Message to Driver";
    return (
        <Tooltip title={tooltipTitle} placement="right">
            <span style={props.style}>
                <Button
                    onClick={() => {
                        let alertMessage = '';
                        if (impact) {
                            impact.tags.forEach((tag: VehicleImpactTag, index: number) => {
                                const label = tag.text;
                                if (index === impact.tags.length - 1) {
                                    alertMessage += `${label}`;
                                } else {
                                    alertMessage += `${label}, `;
                                }
                            });
                        }

                        props.setAlertData({ vehicle: truckId, message: alertMessage, source: source });
                        props.setShowAlertDialog(true);
                    }}
                    variant={'contained'}
                    href="javascript:;"
                    disabled={issueWithSendingMessage !== undefined}
                    size="small"
                    style={compactButtonStyles}
                >
                    <ForwardToInbox />
                </Button>
            </span>
        </Tooltip >
    );
}

function VehicleTimelineView(props: Props) {
    const { userData, selectedVehicle, selectedChartView, timeframe, onCloseClicked, setSelectedChartView, setSelectedAlert, setAlerts } = props;

    const [weatherData, setWeatherData] = React.useState<WeatherData | undefined>(undefined);
    const [showSendAlertDialog, setShowSendAlertDialog] = React.useState<boolean>(false);
    const [vehicleAlertData, setVehicleAlertData] = React.useState<SendAlertData>({ vehicle: '', message: '', source: '' });

    const fetchWeatherData: () => Promise<WeatherData | undefined> = React.useMemo(
        () => {
            return () => {
                const location = vehicleTrackingDataToLocationData(selectedVehicle);
                if (userData.token === undefined || userData.portalToken === undefined || location === undefined) {
                    return Promise.resolve(undefined);
                }

                return fetchWeatherDataOnce(
                    userData.token,
                    userData.portalToken,
                    location,
                    new AbortController(),
                    location.impactSummariesRatingRunTime,
                    true
                );
            };
        },
        [userData.token, selectedVehicle.id]
    );

    React.useEffect(() => {
        // clear initial state when component is reused
        setWeatherData(undefined);

        fetchWeatherData().then(value => {
            setWeatherData(value);
            setAlerts(value?.alerts ?? []);
        });

    }, [fetchWeatherData]);

    let impactString = '';
    if (selectedVehicle.currentImpact !== undefined) {
        impactString = `${wordForImpactLevel(selectedVehicle.currentImpact.overallImpactLevel)}`;
    }
    const impactTags: ImpactTag[] = [];
    selectedVehicle.currentImpact?.tags.forEach(tag => {
        // create separate tags for tags that have been combined with same impact level
        tag.text.split(',').forEach(tagText => {
            impactTags.push({
                id: tag.id,
                source: tag.source,
                text: tagText.trim(),
                value: tag.value,
                impactLevel: tag.impactLevel,
            });
        });
    });

    const hasLinkedRoute = props.matchingRoute !== undefined;

    const timeAgo = new TimeAgo('en-us');

    const subtitle = getSubtitleForVehicle(selectedVehicle);
    const externalUpdatedAt = selectedVehicle.externalUpdatedAt;
    const impactUpdatedAt = selectedVehicle.currentImpact?.lastUpdatedAt;

    const selectedVehicleInfo: JSX.Element = (
        <Paper
            className="selected-asset-info"
            elevation={4}
            style={{
                backgroundColor: brightCardBackgroundColor,
                backgroundImage: 'none',
            }}
        >
            <div style={{
                padding: '17px 18px 0 18px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                boxSizing: 'border-box',
            }}>
                <header style={{ display: 'flex', flexDirection: 'row', alignItems: 'fill', height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Typography variant={'subtitle1'} fontWeight={"bold"} color={'textPrimary'} sx={{ fontSize: 18, lineHeight: '21px' }}>Truck {getAssetName(selectedVehicle)}</Typography>
                        <div style={{ flexBasis: 8 }} />
                        <Typography style={{ opacity: 0.5 }} variant={'caption'} fontWeight={'bold'} sx={{ fontSize: 12, lineHeight: '14px' }}>{subtitle}</Typography>
                        <div style={{ flexBasis: 6 }} />
                        {externalUpdatedAt && <Typography style={{ opacity: 0.5 }} variant={'caption'} sx={{ fontSize: 12, lineHeight: '14px' }}>
                            {`Location updated ${timeAgo.format(externalUpdatedAt)}`}
                        </Typography>}
                        {impactUpdatedAt && <Typography style={{ opacity: 0.5 }} variant={'caption'} sx={{ fontSize: 12, lineHeight: '14px' }}>
                            {`Impact assessed ${timeAgo.format(impactUpdatedAt)}`}
                        </Typography>}
                        <div style={{ overflow: 'auto', height: '100%' }}>
                            <Typography style={{ margin: "5px 0" }} variant={'body2'} fontWeight={"bold"} color={'textPrimary'}>Current Impact Level</Typography>
                            <div style={{ margin: "5px 0 10px", display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                                <Chip
                                    style={{
                                        padding: 8,
                                        // subhourlyImpactSummary is missing if you click on the map on impact tab then switch to nowcast and click on a saved location
                                        backgroundColor: darkTagColorForImpactLevel(selectedVehicle.currentImpact?.overallImpactLevel ?? ImpactLevel.Unknown),
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                    label={impactString}
                                />
                            </div>
                            <div>
                                {(selectedVehicle.currentImpact?.overallImpactLevel ?? ImpactLevel.Unknown) > 0 && <React.Fragment>
                                    <Typography style={{ margin: "5px 0" }} fontWeight={"bold"} variant={'body2'} color={'textPrimary'}>Current Risks ({impactTags.length})</Typography>
                                    <div>
                                        {impactTags.map((tag: ImpactTag) => (
                                            <div>
                                                <Chip
                                                    style={{
                                                        margin: "5px 0",
                                                        padding: 8,
                                                        backgroundColor: darkTagColorForImpactLevel(tag.impactLevel),
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        maxWidth: 250
                                                    }}
                                                    label={tag.text + (tag.value ? ` (${tag.value.toFixed(1)})` : '')}
                                                    onClick={() => {
                                                        if (tag.source === 'governmental_alert') {
                                                            const alert = weatherData?.alerts?.find(a => a.id === tag.id);
                                                            if (alert) {
                                                                setSelectedAlert(alert);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </React.Fragment>}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 2 }}>
                            {/*Buttons container*/}
                            <Tooltip title="Center Map On Vehicle" placement="right">
                                <Button
                                    onClick={() => {
                                        if (selectedVehicle.latitude && selectedVehicle.longitude) {
                                            props.onCenterPressed(selectedVehicle.latitude, selectedVehicle.longitude);
                                        }
                                    }}
                                    variant={'contained'}
                                    href="javascript:;"
                                    size="small"
                                    style={compactButtonStyles}
                                >
                                    <GpsFixed />
                                </Button>
                            </Tooltip>

                            <div style={{ flexBasis: 8 }} />

                            <SendAlertButton
                                vehicle={selectedVehicle}
                                setAlertData={setVehicleAlertData}
                                setShowAlertDialog={setShowSendAlertDialog}
                            />

                            {hasLinkedRoute && <>
                                <div style={{ flexBasis: 8 }} />

                                <Tooltip title="View Shipment" placement="right">
                                    <Button
                                        onClick={() => props.onRoutePressed(selectedVehicle)}
                                        variant={'contained'}
                                        href="javascript:;"
                                        size="small"
                                        style={compactButtonStyles}
                                    >
                                        <Route />
                                    </Button>
                                </Tooltip>
                            </>}

                            <div style={{ flex: '1 1 0' }} />
                        </div>
                    </div>
                </header>
            </div>
        </Paper >);

    let currentConditionsInfo: JSX.Element | undefined = undefined;

    if (props.selectedVehicle.currentConditions) {
        let precipIcon: JSX.Element;
        const precipIconSize = 32;

        switch (props.selectedVehicle.currentConditions.precipitation?.type) {
            case 'rain':
                precipIcon = <WiRain size={precipIconSize} />;
                break;

            case 'snow':
                precipIcon = <WiSnow size={precipIconSize} />;
                break;

            case 'freezing_rain':
                precipIcon = <WiRainMix size={precipIconSize} />;
                break;

            default:
                precipIcon = <WiCloud size={precipIconSize} />;
                break;
        }

        currentConditionsInfo = (
            <div style={{ width: '100%', overflow: 'auto' }} >
                <div style={{ backgroundColor: cardBackgroundColor, height: 220, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', paddingBottom: 13, paddingLeft: 4 }}>
                    <ConditionCell
                        title={'Temperature'}
                        icon={<div style={{ fontSize: 24, color: 'white', fontWeight: 'bold', }}>
                            {Math.round(props.selectedVehicle.currentConditions.temperature.value)}°F
                        </div>}
                        text={snakeCaseToTitleCase(props.selectedVehicle.currentConditions.temperature.label)}
                        textColor={conditionColors[props.selectedVehicle.currentConditions.temperature.color]}
                    />

                    {props.selectedVehicle.currentConditions.precipitation ?
                        <ConditionCell
                            title={snakeCaseToTitleCase(props.selectedVehicle.currentConditions.precipitation.type)}
                            icon={<Tooltip title={`${props.selectedVehicle.currentConditions.precipitation.value} ${props.selectedVehicle.currentConditions.precipitation.units}`}>
                                {precipIcon}
                            </Tooltip>}
                            text={snakeCaseToTitleCase(props.selectedVehicle.currentConditions.precipitation.label)}
                            textColor={conditionColors[props.selectedVehicle.currentConditions.precipitation.color]}
                        />
                        :
                        <ConditionCell
                            title={'Precipitation'}
                            icon={precipIcon}
                            text={'None'}
                            textColor={conditionColors['green']}
                        />
                    }

                    <ConditionCell
                        title={'Visibility'}
                        icon={<Tooltip title={`${props.selectedVehicle.currentConditions.visibility.value.toFixed(2)} mi`}>
                            <Visibility />
                        </Tooltip>}
                        text={snakeCaseToTitleCase(props.selectedVehicle.currentConditions.visibility.label)}
                        textColor={conditionColors[props.selectedVehicle.currentConditions.visibility.color]}
                    />

                    <ConditionCell
                        title={'Wind'}
                        icon={<South sx={{ transform: "rotate(" + props.selectedVehicle.currentConditions.wind.directionDegrees + "deg)" }} />}
                        text={`${Math.round(props.selectedVehicle.currentConditions.wind.value)} mph from ${props.selectedVehicle.currentConditions.wind.directionCardinal}`}
                        subtext={'Gusts up to ' + Math.round(props.selectedVehicle.currentConditions.wind.maxGust) + ' mph'}
                        textColor={conditionColors[props.selectedVehicle.currentConditions.wind.color]}
                    />

                    <ConditionCell
                        title={'Road Risk'}
                        icon={<div style={{
                            width: '66%',
                            height: 10,
                            backgroundColor: '#D9D9D9',
                            borderRadius: 5,
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div style={{
                                width: `${props.selectedVehicle.currentConditions.roadRisk.value / 10 * 100}%`,
                                minWidth: 10,
                                height: '100%',
                                backgroundColor: conditionColors[props.selectedVehicle.currentConditions.roadRisk.color], borderRadius: 5
                            }} />

                            <div style={{ flex: '1 1 0' }} />
                        </div>}
                        text={snakeCaseToTitleCase(props.selectedVehicle.currentConditions.roadRisk.label)}
                        subtext={`${props.selectedVehicle.currentConditions.roadRisk.value.toFixed(1)}/10`}
                        textColor={conditionColors[props.selectedVehicle.currentConditions.roadRisk.color]}
                    />

                    {props.selectedVehicle.currentConditions.tippingIndex && <ConditionCell
                        title={'Tipping Risk'}
                        icon={<div style={{
                            width: '66%',
                            height: 10,
                            backgroundColor: '#D9D9D9',
                            borderRadius: 5,
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div style={{
                                width: `${props.selectedVehicle.currentConditions.tippingIndex.value / 10 * 100}%`,
                                minWidth: 10,
                                height: '100%',
                                backgroundColor: conditionColors[props.selectedVehicle.currentConditions.tippingIndex.color], borderRadius: 5
                            }} />

                            <div style={{ flex: '1 1 0' }} />
                        </div>}
                        text={snakeCaseToTitleCase(props.selectedVehicle.currentConditions.tippingIndex.label)}
                        subtext={`${props.selectedVehicle.currentConditions.tippingIndex.value.toFixed(1)}/10`}
                        textColor={conditionColors[props.selectedVehicle.currentConditions.tippingIndex.color]}
                    />}
                </div>
            </div>
        );
    } else {
        currentConditionsInfo = (
            <div style={{ backgroundColor: cardBackgroundColor, height: 143, paddingBottom: 13, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                No current conditions available
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            {selectedVehicleInfo}
            {selectedChartView === 'current_conditions' ?
                <div style={{ marginTop: '15px', overflow: 'auto', width: '100%' }} >
                    <Paper elevation={3} style={{ position: 'relative', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: cardBackgroundColor, backgroundImage: 'none', ...props.extraStyles }}>
                        <header style={{ padding: '12px 12px 0px 5px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Select
                                value={selectedChartView}
                                variant={'outlined'}
                                style={{ margin: "0px 5px", width: 200, height: 40 }}
                                sx={{
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white",
                                        borderRadius: '6px',
                                    },
                                }}
                                onChange={(event) => setSelectedChartView(event.target.value)}
                            >
                                {VEHICLE_CHART_VIEWS.map(chartView => (
                                    <MenuItem value={chartView.key}>{chartView.title}</MenuItem>
                                ))}
                            </Select>
                            <div style={{ flex: '1 1 0' }} />
                            <IconButton
                                color={'primary'}
                                onClick={() => onCloseClicked?.()}
                                style={{ height: 30 }}
                            >
                                <Close />
                            </IconButton>
                        </header>

                        <div style={{ backgroundColor: '#979797', marginTop: 8, marginBottom: 8, marginLeft: 10, marginRight: 10, height: 1 }} />

                        {currentConditionsInfo}
                    </Paper>
                </div> :
                <AlertTimelineView
                    selectedChartView={selectedChartView}
                    chartViews={VEHICLE_CHART_VIEWS}
                    alerts={weatherData?.alerts}
                    timeframe={timeframe}
                    timezone={undefined}
                    onCloseClicked={() => onCloseClicked?.()}
                    setSelectedChartView={(view) => setSelectedChartView(view)}
                    setSelectedAlert={(alert) => setSelectedAlert(alert)}
                />}



            {showSendAlertDialog && <SendAlertDialog
                alertData={vehicleAlertData}
                selectedVehicle={selectedVehicle}
                showAlertDialog={showSendAlertDialog}
                setShowAlertDialog={setShowSendAlertDialog}
                onVehicleMessageRequested={props.onVehicleMessageRequested}
            />}

        </div>
    );
}

export default VehicleTimelineView;
