import moment, { Moment } from "moment";
import 'moment-timezone';
import { RouteData, VehicleData } from "../../../types/routes";
import { HourRatingsData, VehicleTrackingData } from "../../../types";

export interface Timeframe {
    name: string; // human-readable label
    label: string; // code-level label
    startTime: Moment;
    endTime: Moment;
}

declare global {
    interface Date {
        between(min: Date, max: Date): boolean;
    }
    interface Array<T> {
        max(): T | undefined;
    }
}

Date.prototype.between = function (min: Date, max: Date) {
    return this >= min && this <= max;
};

Array.prototype.max = function () {
    let len = this.length, max = -Infinity;
    while (len--) {
        if (this[len] > max) {
            max = this[len];
        }
    }
    if (max === -Infinity) {
        return undefined;
    }
    return max;
};

export const filterRoutes = (routes: RouteData[], timeframe: Timeframe) => {
    const startTime = timeframe.startTime.toDate();
    const endTime = timeframe.endTime.toDate();
    return routes.filter(route => {
        let latestArrivalTime: Date | undefined = route.latestRouteResults?.map(result => result.adjustedArrivalTime).max();
        let arrivalTimeCheck = false;
        if (latestArrivalTime !== undefined) {
            // add 6 hours to predicted ETA in case of a closure or significant delay that we were unable to capture
            latestArrivalTime = new Date(latestArrivalTime.getTime() + 6 * 3600 * 1000);
            // second condition checks if a route starts before and ends after the timeframe
            // since route intersects the timeframe, lets include it
            arrivalTimeCheck = latestArrivalTime.between(startTime, endTime) || (
                startTime.between(route.departureTime, latestArrivalTime) && endTime.between(route.departureTime, latestArrivalTime)
            );
        }
        return route.departureTime.between(startTime, endTime) || arrivalTimeCheck;
    });
};

export const updateTimeframeForTimezone = (timeframe: Timeframe, timezone: string, impactSummariesUpdatedAt: Date | undefined) => {
    if (timezone === undefined) {
        return timeframe;
    }

    let timezoneTimeframe: Timeframe = { name: timeframe.name, label: timeframe.label, startTime: timeframe.startTime, endTime: timeframe.endTime };
    const currentTime = moment(impactSummariesUpdatedAt || moment()).tz(timezone);

    // shift the timeframes for the timezone and last updated at time
    if (timezoneTimeframe.label === "24hours") {
        timezoneTimeframe.startTime = currentTime.clone().startOf('day');
        timezoneTimeframe.endTime = currentTime.clone().add(1, 'day');
    }
    if (timezoneTimeframe.label === "today") {
        timezoneTimeframe.startTime = currentTime.clone().startOf('day');
        timezoneTimeframe.endTime = currentTime.clone().add(1, 'day').startOf('day');
    }
    if (timezoneTimeframe.label === 'tomorrow') {
        timezoneTimeframe.startTime = currentTime.clone().add(1, 'day').startOf('day');
        timezoneTimeframe.endTime = currentTime.clone().add(2, 'days').startOf('day');
    }
    if (timezoneTimeframe.label === 'day3_7') {
        timezoneTimeframe.startTime = currentTime.clone().add(2, 'days').startOf('day');
        timezoneTimeframe.endTime = currentTime.clone().add(1, 'week');
    }

    return timezoneTimeframe;
};

export const filterImpactHours = (hours: HourRatingsData[], timeframe: Timeframe) => {
    return hours.filter(hour => {
        return hour.time >= timeframe.startTime.toDate() &&
            hour.time < timeframe.endTime.toDate();
    });
};

export const findMatchingRouteForVehicle = (vehicle: VehicleData | undefined, allRoutes: RouteData[]): RouteData | undefined => {
    if (vehicle === undefined) {
        return undefined;
    }
    const currentDate = new Date();
    // find all routes that have this vehicle's id because there can be multiple
    // route needs to depart in the past for it to have a match
    const matchingRoutes = allRoutes.filter(route => {
        if (route.vehicleData?.id !== vehicle.id) {
            return false;
        }
        // same logic as on Rails server in Route#active?
        if (route.source === 'platform_science' || route.source === 'samsara') {
            return route.status === 'active' && route.lastCompletedIndex !== undefined && route.lastCompletedIndex >= 0;
        }
        return route.departureTime < currentDate;
    });
    // choose the oldest departure time as the one to match if there are multiple
    // TODO: some other parameters we can use to pick better which one to use?
    const sortedRoutes = matchingRoutes.sort((a, b) => a.departureTime.getTime() - b.departureTime.getTime());
    return sortedRoutes.length > 0 ? sortedRoutes[0] : undefined;
};

export const findMatchingVehicleForRoute = (route: RouteData | undefined, allRoutes: RouteData[], allVehicles: VehicleTrackingData[]): VehicleTrackingData | undefined => {
    if (route === undefined || route.vehicleData?.id === undefined) {
        return undefined;
    }
    const vehicle = allVehicles.find(vehicle => vehicle.id === route.vehicleData?.id);
    const matchingRouteForVehicle = findMatchingRouteForVehicle(vehicle, allRoutes);
    // if the best matched route matches this route, then return the vehicle
    // the route id can be a string because the routes table reassigns it for the tree structure
    // so we explicitly convert both to strings before comparison
    return matchingRouteForVehicle?.id?.toString() === route.id?.toString() ? vehicle : undefined;
};

export const findTimeframeIndexForTime = (time: Date, timeframes: Timeframe[]) => {
    for (const index in timeframes) {
        const startTime = timeframes[index].startTime.toDate();
        const endTime = timeframes[index].endTime.toDate();
        if (time.between(startTime, new Date(endTime.getTime() - 1))) {
            return parseInt(index);
        }
    }
    return undefined;
};