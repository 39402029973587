import { AddOutlined, DeleteOutline, Help } from '@mui/icons-material';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputAdornment,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slider,
    TextField,
    TextFieldProps,
    Tooltip,
    Typography
} from '@mui/material';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { AutoShowErrorDialog } from 'src/components/shared/AutoShowErrorDialog';
import { ClientId, DriverNotification, DriverNotificationFrequency, DriverNotificationThreshold, isValidUserForFeature } from 'src/types';
import { formatRatingKey } from 'src/types/RatingKey';

interface Props {
    userId?: number;
    driverNotificationToEdit: DriverNotification | undefined;
    creatingNewDriverNotification: boolean;
    editDriverNotificationResponse: DriverNotification | Error[] | undefined;
    alerts: string[];
    setCreatingNewDriverNotification: (creating: boolean) => void;
    setDriverNotificationToEdit: (driverNotification: DriverNotification | undefined) => void;
    onEditedDriverNotificationSaved: (driverNotification: DriverNotification) => void;
    onCreatedDriverNotificationSaved: (driverNotification: DriverNotification) => void;
    onEditedDriverNotificationFinished: () => void;
}

const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min)) + min;
};

const formatThresholds = (thresholds: DriverNotificationThreshold[] | undefined, messageFormat: string = 'current') => {
    let formattedThresholds: string = '';
    if (thresholds) {
        for (let threshold of thresholds) {
            formattedThresholds += `${formatRatingKey(threshold.index)} risk is in the range: ${threshold.lowerBound} - ${threshold.upperBound}`;
            if (messageFormat !== 'current') {
                formattedThresholds += `, begins in ${getRandomInt(1, 4)} mile and continues for ${getRandomInt(10, 20)} miles\n`;
            } else {
                formattedThresholds += '\n';
            }
        }
    }
    return formattedThresholds;
};

const formatAlerts = (alerts: string[] | undefined, messageFormat: string = 'current') => {
    let formattedAlerts: string = '';
    if (alerts) {
        if (messageFormat === 'current') {
            formattedAlerts = alerts.map(alert => alert).join(', ');
        } else {
            alerts.map(alert => formattedAlerts += `${alert} begins in ${getRandomInt(2, 5)} miles and continues for ${getRandomInt(5, 15)} miles\n`);
        }
    }
    return formattedAlerts;
};

interface TemplateVariable {
    key: string;
    allowedInLegacy?: boolean;
    expose?: boolean;
    getPreview: (driverNotification: DriverNotification) => string;
}

const variables = [{
    key: '{risk scores}',
    allowedInLegacy: true,
    getPreview: (driverNotification: DriverNotification) => formatThresholds(driverNotification.thresholds)
}, {
    key: '{alerts}',
    allowedInLegacy: true,
    getPreview: (driverNotification: DriverNotification) => formatAlerts(driverNotification.alerts)
}, {
    key: '{event}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => driverNotification.alerts[0] ?? 'Severe Thunderstorm Warning'
}, {
    key: '{severity}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'Minor',
}, {
    key: '{certainty}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'Observed',
}, {
    key: '{urgency}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'Expected',
}, {
    key: '{sent}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'September 17 at 1:25PM MDT',
}, {
    key: '{effective}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'September 17 at 1:25PM MDT',
}, {
    key: '{expires}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'September 17 at 2:00PM MDT',
}, {
    key: '{headline}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => `${driverNotification.alerts[0] ?? 'Severe Thunderstorm Warning'} issued September 17 at 1:25PM MDT by NWS Pueblo CO`,
}, {
    key: '{description}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'At 125 PM MDT, Doppler radar was tracking a strong thunderstorm over\nBlanca, or 17 miles east of San Luis Valley Airport, moving northeast\nat 50 mph.\n\nHAZARD...Wind gusts of 50 to 55 mph and penny size hail.\n\nSOURCE...Radar indicated.\n\nIMPACT...Gusty winds could knock down tree limbs and blow around\nunsecured objects. Minor hail damage to vegetation is\npossible.\n\nLocations impacted include...\nBlanca, La Veta Pass, Red Wing, Fort Garland, Blanca Peak, Spring\nBurn Scar south of Highway 160 in Costilla county, Spring Burn Scar\nnorth of Highway 160, and Spring Burn Scar south of Highway 160 in\nHuerfano county.',
}, {
    key: '{instruction}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'If outdoors, consider seeking shelter inside a building.\n\nA Severe Thunderstorm Watch remains in effect until 700 PM MDT for\nsouth central and southeastern Colorado.',
}, {
    key: '{response}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'Execute',
}, {
    key: '{area_description}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'Alamosa  Vicinity/Central San Luis Valley Below 8500 Ft; Southern San Luis Valley; Northern Sangre de Cristo Mountains Between 8500 And 11000 Ft; Northern Sangre de Cristo Mountains above 11000 Ft; Southern Sangre De Cristo Mountains Between 7500 and 11000 Ft; Walsenburg Vicinity/Upper Huerfano River Basin Below 7500 Ft',
}, {
    key: '{sender_name}',
    expose: true,
    getPreview: (driverNotification: DriverNotification) => 'NWS Pueblo CO',
}];

const generateNotificationPreview = (driverNotification: DriverNotification, inLegacyMode: boolean) => {
    if (!driverNotification.template) {
        return '';
    }

    let preview = driverNotification.template;

    for (const variable of variables) {
        if (inLegacyMode && variable.allowedInLegacy !== true) {
            continue;
        }
        preview = preview.replaceAll(variable.key, variable.getPreview(driverNotification));
    }

    return preview;
};

export const EditDriverNotificationDialog = ({ userId, driverNotificationToEdit, creatingNewDriverNotification, editDriverNotificationResponse, alerts, setCreatingNewDriverNotification, setDriverNotificationToEdit, onCreatedDriverNotificationSaved, onEditedDriverNotificationSaved, onEditedDriverNotificationFinished }: Props) => {
    const [isSavingEdit, setSavingEdit] = React.useState(false);
    const [editErrors, setEditErrors] = React.useState<string[]>([]);

    const [selectedIndex, setSelectedIndex] = React.useState('road');
    const [bounds, setBounds] = React.useState([0, 10]);

    const [driverNotificationError, setDriverNotificationError] = React.useState<string | undefined>(undefined);

    const [alertsFilter, setAlertsFilter] = React.useState('');
    const [filteredAlerts, setFilteredAlerts] = React.useState(alerts);

    const oldDriverNotificationToEdit = React.useRef(undefined);
    const [inLegacyMode, setInLegacyMode] = React.useState(false);

    React.useEffect(() => {
        if (driverNotificationToEdit === undefined || oldDriverNotificationToEdit.current === driverNotificationToEdit) {
            // wait for driver notification to be populated
            return;
        }
        setInLegacyMode(driverNotificationToEdit.alerts.length > 1);
    }, [driverNotificationToEdit]);

    React.useEffect(() => {
        if (editDriverNotificationResponse === undefined) {
            return;
        }
        setSavingEdit(false);
        if (Array.isArray(editDriverNotificationResponse)) {
            setEditErrors(editDriverNotificationResponse.map(e => e.message));
        } else {
            onEditedDriverNotificationFinished();
            setDriverNotificationToEdit(undefined);
        }
    }, [editDriverNotificationResponse]);

    React.useEffect(() => {
        setFilteredAlerts(alerts.filter(alert => alert.toLowerCase().includes(alertsFilter.trim().toLowerCase())));
    }, [alerts, alertsFilter]);

    if (driverNotificationToEdit === undefined) {
        return <div></div>;
    }

    const handleCancel = () => {
        setSavingEdit(false);
        setEditErrors([]);
        setCreatingNewDriverNotification(false);
        setDriverNotificationToEdit(undefined);
    };

    const handleSave = () => {
        setSavingEdit(true);
        setEditErrors([]);
        if (creatingNewDriverNotification) {
            setCreatingNewDriverNotification(false);
            onCreatedDriverNotificationSaved(driverNotificationToEdit);
        } else {
            onEditedDriverNotificationSaved(driverNotificationToEdit);
        }

    };

    const missingNecessaryData = () => {
        if (driverNotificationToEdit.name === undefined || driverNotificationToEdit.name === '') return true;
        if (driverNotificationToEdit.notificationType === 'impact' && driverNotificationToEdit.thresholds?.length === 0) return true;
        if (driverNotificationToEdit.notificationType === 'alert' && driverNotificationToEdit.alerts?.length === 0) return true;

        return false;
    };

    const sharedTextFieldProps: Partial<TextFieldProps> = {
        autoFocus: true,
        margin: 'dense',
        fullWidth: true,
        variant: 'standard',
    };

    const updateSelectedAlerts = (alert: string) => {
        const updatedDriverNotification = {
            ...driverNotificationToEdit,
        };

        if (inLegacyMode) {
            if (updatedDriverNotification.alerts.includes(alert)) {
                updatedDriverNotification.alerts = updatedDriverNotification.alerts.filter(a => a !== alert);
            } else {
                updatedDriverNotification.alerts = [...updatedDriverNotification.alerts, alert];
            }
        } else {
            if (updatedDriverNotification.alerts.includes(alert)) {
                updatedDriverNotification.alerts = [];
            } else {
                updatedDriverNotification.alerts = [alert];
            }
        }

        setDriverNotificationToEdit(updatedDriverNotification);
    };

    const thresholdColumns = [{
        field: 'id',
        hide: true
    }, {
        field: 'index',
        headerName: 'Impact Type',
        flex: 2,
        renderCell: (params: GridRenderCellParams) => {
            return formatRatingKey(params.row.index);
        }
    }, {
        field: 'lowerBound',
        headerName: 'Start',
        flex: 1
    }, {
        field: 'upperBound',
        headerName: 'End',
        flex: 1
    }, {
        // fake field name to prevent the header from being highlighted weirdly
        field: 'actions',
        headerName: 'Delete',
        sortable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <div>
                    <Button
                        onClick={() => {
                            const updatedDriverNotification = {
                                ...driverNotificationToEdit,
                            };
                            updatedDriverNotification.thresholds = updatedDriverNotification.thresholds.filter(t => t.id !== params.id);
                            setDriverNotificationToEdit(updatedDriverNotification);
                        }}
                    >
                        <DeleteOutline />
                    </Button>
                </div>
            );
        }
    }];

    const thresholdMarks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 2,
            label: '2',
        },
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 5,
            label: '5',
        },
        {
            value: 6,
            label: '6',
        },
        {
            value: 7,
            label: '7',
        },
        {
            value: 8,
            label: '8',
        },
        {
            value: 9,
            label: '9',
        },
        {
            value: 10,
            label: '10',
        },
    ];

    const addVariableToTemplate = (variable: TemplateVariable) => {
        setDriverNotificationToEdit({
            ...driverNotificationToEdit,
            template: (driverNotificationToEdit.template ?? '') + ' ' + variable.key
        });
    };

    return (
        <Dialog open={true} onClose={handleCancel} fullWidth={true} maxWidth={'xl'}>
            <DialogTitle>Driver Notification</DialogTitle>
            <DialogContent>
                {editErrors.length > 0 && <DialogContentText>
                    <div>
                        There was an error saving your edits:
                        <br />
                        <ul>
                            {editErrors.map(err => <li>{err}</li>)}
                        </ul>
                    </div>
                </DialogContentText>}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '40px' }}>
                    <TextField
                        {...sharedTextFieldProps}
                        id='name'
                        label='Name'
                        type='text'
                        style={{ display: 'flex', flex: 2 }}
                        value={driverNotificationToEdit.name}
                        onChange={(event) => {
                            const updatedDriverNotification = {
                                ...driverNotificationToEdit,
                            };
                            updatedDriverNotification.name = event.target.value as string;
                            setDriverNotificationToEdit(updatedDriverNotification);
                        }}
                    />
                    <FormControl>
                        <FormControlLabel
                            control={<Checkbox checked={driverNotificationToEdit.urgent}
                                onChange={(event) => {
                                    const updatedDriverNotification = {
                                        ...driverNotificationToEdit,
                                    };
                                    updatedDriverNotification.urgent = event.target.checked;
                                    setDriverNotificationToEdit(updatedDriverNotification);
                                }}
                            />}
                            label="Urgent" />
                        <FormHelperText style={{ maxWidth: '300px' }}>Urgent is required for notification to be read out loud while driving for supported ELDs.</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Notification Status</FormLabel>
                        <Select
                            value={driverNotificationToEdit.status}
                            variant={'outlined'}
                            style={{ width: '120px', height: '50px' }}
                            onChange={(event) => {
                                const updatedDriverNotification = {
                                    ...driverNotificationToEdit,
                                };
                                updatedDriverNotification.status = event.target.value;
                                setDriverNotificationToEdit(updatedDriverNotification);
                            }}
                        >
                            <MenuItem value={'enabled'}>Enabled</MenuItem>
                            <MenuItem value={'testing'}>Testing</MenuItem>
                            <MenuItem value={'disabled'}>Disabled</MenuItem>
                        </Select>
                    </FormControl>
                    <div style={{ display: 'flex' }}>
                        <FormControl>
                            <FormLabel>Notification Frequency</FormLabel>
                            <RadioGroup
                                value={driverNotificationToEdit.frequency}
                                onChange={(event) => {
                                    const updatedDriverNotification = {
                                        ...driverNotificationToEdit,
                                    };
                                    updatedDriverNotification.frequency = event.target.value as DriverNotificationFrequency;
                                    setDriverNotificationToEdit(updatedDriverNotification);
                                }}
                            >
                                <FormControlLabel value='change' control={<Radio />} label={driverNotificationToEdit.notificationType === 'impact' ? 'Threshold breached' : 'Alert received'} />
                                <FormControlLabel value='interval' control={<Radio />} label='Every N Minutes' />
                            </RadioGroup>
                        </FormControl>
                        {driverNotificationToEdit.frequency === 'interval' && <div style={{ height: '100%', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                            <FormControl variant="outlined">
                                <TextField
                                    type="number"
                                    style={{ width: '120px', height: '40px' }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mins</InputAdornment>
                                    }}
                                    value={driverNotificationToEdit.interval}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const regex = /^[0-9\b]+$/;
                                        if (regex.test(event.target.value)) {
                                            const updatedDriverNotification = {
                                                ...driverNotificationToEdit,
                                            };
                                            updatedDriverNotification.interval = parseInt(event.target.value);
                                            setDriverNotificationToEdit(updatedDriverNotification);
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>}
                    </div>
                    {isValidUserForFeature(userId, { 'production': [ClientId.Production.Werner, ClientId.Production.PGT, ClientId.Production.US_Xpress, ClientId.Production.Stevens] }) && <div style={{ display: 'flex' }}>
                        <FormControl>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormLabel>Route Lookahead</FormLabel>
                                <Tooltip title={`Amount of time to lookahead for ${driverNotificationToEdit.notificationType === 'impact' ? 'threshold breaches' : 'NWS alerts'} on your routes. Requires your routes to be synced to vehicles to work.`}>
                                    <Help style={{ color: 'grey' }} />
                                </Tooltip>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Select
                                    value={driverNotificationToEdit.windowSize}
                                    variant={'outlined'}
                                    style={{ width: '100%' }}
                                    onChange={(event) => {

                                        const updatedDriverNotification = {
                                            ...driverNotificationToEdit,
                                        };
                                        updatedDriverNotification.windowSize = event.target.value as number;
                                        setDriverNotificationToEdit(updatedDriverNotification);
                                    }}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                </Select>
                                <div style={{ height: '100%', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                                    {driverNotificationToEdit.windowSize === 1 ? 'Hour' : 'Hours'}
                                </div>
                            </div>
                        </FormControl>
                    </div>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', height: '50vh', width: '100%', gap: '40px', marginTop: '20px' }}>
                    {driverNotificationToEdit.notificationType === 'impact' && <div style={{ display: 'flex', flexDirection: 'column', height: '100%', flex: 1 }}>
                        <Typography>Thresholds</Typography>
                        {driverNotificationToEdit.thresholds === undefined || driverNotificationToEdit.thresholds.length === 0 ?
                            (<div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                                No thresholds have been created
                            </div>)
                            : <DataGridPro
                                columns={thresholdColumns}
                                rows={driverNotificationToEdit.thresholds}
                                pageSize={4}
                                pagination
                                autoHeight
                                disableSelectionOnClick
                            />}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Select
                                value={selectedIndex}
                                variant={'outlined'}
                                style={{ width: '100%' }}
                                onChange={(event) => setSelectedIndex(event.target.value)}
                            >
                                <MenuItem value={'road'}>{formatRatingKey('road')}</MenuItem>
                                <MenuItem value={'disruption'}>{formatRatingKey('disruption')}</MenuItem>
                                <MenuItem value={'flood'}>{formatRatingKey('flood')}</MenuItem>
                                <MenuItem value={'power'}>{formatRatingKey('power')}</MenuItem>
                                <MenuItem value={'life_property'}>{formatRatingKey('life_property')}</MenuItem>
                            </Select>
                            <Slider
                                value={bounds}
                                onChange={(event: Event, newValue: number | number[]) => setBounds(newValue as number[])}
                                valueLabelDisplay='auto'
                                disableSwap
                                min={1}
                                step={1}
                                max={10}
                                marks={thresholdMarks}
                                style={{ width: '90%', margin: '20px' }}
                            />
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                style={{ marginTop: '20px' }}
                                onClick={() => {
                                    const thresholdId = `${selectedIndex}_${bounds[0]}_${bounds[1]}`;
                                    const alreadyExists = driverNotificationToEdit.thresholds.filter(t => t.id === thresholdId).length > 0;
                                    if (alreadyExists) {
                                        setDriverNotificationError("Thresholds must be unique");
                                    } else {
                                        const updatedDriverNotification = {
                                            ...driverNotificationToEdit,
                                        };
                                        const driverNotificationThresholds = { id: thresholdId, index: selectedIndex, lowerBound: bounds[0], upperBound: bounds[1] } as DriverNotificationThreshold;
                                        updatedDriverNotification.thresholds = [...updatedDriverNotification.thresholds, driverNotificationThresholds];
                                        setDriverNotificationToEdit(updatedDriverNotification);
                                    }
                                }}
                            >
                                <AddOutlined />
                                Add Threshold
                            </Button>
                        </div>
                    </div>}
                    {driverNotificationToEdit.notificationType === 'alert' && <div style={{ display: 'flex', flexDirection: 'column', height: '100%', flex: 1 }}>
                        <Typography>Alerts</Typography>
                        <TextField
                            {...sharedTextFieldProps}
                            id='alertsFilter'
                            label='Filter'
                            type='text'
                            value={alertsFilter}
                            onChange={(event) => {
                                setAlertsFilter(event.target.value as string);
                            }} />
                        {/* NOTE: the progress indicator is no longer shown here which is fine in current state since its a local search */}
                        {filteredAlerts.length >= 0 ? <List dense={true} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: '0px', overflowY: 'scroll' }}>
                            {filteredAlerts.length === 0 ? <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: "100%", alignItems: 'center', justifyContent: 'center' }}>
                                <Typography>No results found</Typography>
                            </div> : filteredAlerts.map((alert) => (
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox checked={driverNotificationToEdit.alerts.includes(alert)} onChange={() => updateSelectedAlerts(alert)} />
                                    </ListItemIcon>
                                    <ListItemText primary={alert} />
                                </ListItem>
                            ))}
                        </List>
                            : <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: "100%", alignItems: 'center', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>}
                    </div>}
                    <Divider orientation="vertical" flexItem />
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', flex: 2 }}>
                        <div style={{ height: '45%', width: '100%', marginBottom: '40px' }}>
                            <Typography>Notification Preview</Typography>
                            <Card elevation={5} sx={{ height: '100%', overflow: 'auto', backgroundColor: '#111111', borderRadius: 5 }}>
                                <CardContent>
                                    <Typography style={{ whiteSpace: 'pre-line' }}>
                                        {generateNotificationPreview(driverNotificationToEdit, inLegacyMode)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>

                        <Typography variant="h6">Edit Template</Typography>

                        {!inLegacyMode &&
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                                <Typography variant="caption" style={{ fontWeight: 'regular', marginRight: 10, marginTop: 2 }}>Variables:</Typography>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {variables
                                        .filter(v => v.expose === true)
                                        .map(variable => (
                                            <Chip
                                                size="small"
                                                key={variable.key}
                                                label={variable.key.replace('{', '').replace('}', '')}
                                                onClick={() => addVariableToTemplate(variable)}
                                                style={{ marginRight: 8, marginBottom: 4 }}
                                            />
                                        ))}
                                </div>
                            </div>
                        }

                        <TextField
                            {...sharedTextFieldProps}
                            id='template'
                            // label='Edit Template'
                            type='text'
                            multiline
                            rows={10}
                            maxRows={10}
                            value={driverNotificationToEdit.template}
                            style={{ display: 'flex', height: '45%', width: '100%' }}
                            inputProps={{ style: { color: 'black', backgroundColor: 'white' } }}
                            onChange={(event) => {
                                const updatedDriverNotification = {
                                    ...driverNotificationToEdit,
                                };
                                updatedDriverNotification.template = event.target.value as string;
                                setDriverNotificationToEdit(updatedDriverNotification);
                            }}
                        />
                    </div>
                </div>
            </DialogContent>
            <AutoShowErrorDialog
                title={"Error"}
                error={driverNotificationError}
                onDismiss={() => setDriverNotificationError(undefined)}
            />
            {
                !isSavingEdit && <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleSave} disabled={missingNecessaryData()}>Save</Button>
                </DialogActions>
            }
            {isSavingEdit && <LinearProgress />}
        </Dialog >
    );
};
