import chroma from "chroma-js";
import React from "react";
import { Blurb, FloodChanceData, UserState } from "../../../types";
import { GRANULAR_RATINGS_HOST, cardBackgroundColor } from "../../../constants";
import { GeoJSONLayer } from "../../Public/GovernmentalAlerts";
import { Typography } from "@mui/material";
import { LegendComponent } from "../Impact/LegendComponent";
import { getContrastYIQ } from "../Impact/MapManager";

const floodColors = [
    "#C1E1D7",
    "#C1E1D7",
    "#97DCC6",
    "#76C9AF",
    "#51ABA6",
    "#36839D",
    "#34569F",
    "#1E3C90",
    "#341B80",
    "#6A32AB",
    "#9337D0",
];
const floodChanceScale: chroma.Scale = chroma.scale(floodColors).domain([0, 1]);

export interface Props {
    userData?: UserState;

    setFloodChanceGeojsonLayers: (layers: GeoJSONLayer[]) => void;
}

export const FloodChanceLegend = ({
    userData,
    setFloodChanceGeojsonLayers,
}: Props) => {
    const [, setFloodChanceData] = React.useState<FloodChanceData[]>([]);

    React.useEffect(() => {
        if (userData?.portalToken === undefined) {
            return;
        }

        fetch(`${GRANULAR_RATINGS_HOST}/afb/flood-chance?token=${userData.portalToken}`)
            .then((response: Response) => response.json())
            .then((json: any) => {
                if (json.error) {
                    console.log('failed to load AFB flood chance, error:', json.error);
                    return;
                }

                const floodChanceDatas: FloodChanceData[] = json.features.map((feature: any) => {
                    return {
                        name: feature.properties.name,
                        date: new Date(Date.parse(feature.properties.date)),
                        floodProbability: feature.properties.flood_probability,
                        geoJson: feature,
                    };
                });

                setFloodChanceData(floodChanceDatas);
                setFloodChanceGeojsonLayers(floodChanceDatas.map(data => {
                    return {
                        // use an id that will change if we get new data
                        id: `${data.name}_${data.date.toISOString()}`,
                        geojsonData: data.geoJson,
                        style: (feature: any) => {
                            const name = feature.getProperty('name') as string;
                            const floodProbability = feature.getProperty('flood_probability') as number;
                            const floodProbabilityPercent = Math.floor(floodProbability * 100);
                            const floodProbabilityPercentStr = `${floodProbabilityPercent.toFixed(0)}%`;
                            const fillColor = floodChanceScale(floodProbability).hex();
                            return {
                                title: `${name}: ${floodProbabilityPercentStr} chance of flooding over the next 48 hours`,
                                label: {
                                    text: floodProbabilityPercentStr,
                                    fontSize: '12px',
                                    color: getContrastYIQ(fillColor),
                                },
                                icon: {
                                    path: google.maps.SymbolPath.CIRCLE,
                                    fillColor,
                                    fillOpacity: 1.0,
                                    strokeColor: "white",
                                    strokeWeight: 2.0,
                                    scale: 14,
                                },
                                zIndex: 10000 + floodProbabilityPercent,
                            };
                        },
                        onClick: (feature: any) => {

                        },
                    };
                }));
            });
    }, [userData?.portalToken]);

    const floodChanceBlurbs: Blurb[] = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(floodChance => {
        return {
            blurb: `${floodChance}% chance of flooding over the next 48 hours`,
            color: floodChanceScale(floodChance / 100).hex(),
            value: floodChance,
        };
    });
    const floodChanceLegend = (
        <div className={"dashboard-alerts-legend"} style={{ "padding": "10px 15px 15px 0px", backgroundColor: cardBackgroundColor }}>
            <div>
                <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'red', textAlign: 'center' }}>***EXPERIMENTAL***</Typography>
                <div style={{ "padding": "15px 25px 15px 25px", backgroundColor: cardBackgroundColor }}>
                    <div className={'impact-tile-legend'}>
                        <LegendComponent
                            blurbs={floodChanceBlurbs}
                            skipFirstBlurb={true}
                            embedValues={true}
                            legendTitle="Flood Chance"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
    return floodChanceLegend;
};
