import * as React from "react";
import { Redirect } from "react-router";
import { UserState } from "src/types";
import { CircularProgress, Typography } from "@mui/material";
import { ScaledIcon } from "src/components/ScaledIcon";

export interface Props {
    userData?: UserState;

    initiateLogin: (loginData: any) => void;
}

export const GeotabAuthPage = ({
    userData, initiateLogin
}: Props) => {
    const [invalidAuthQueryParams, setInvalidAuthQueryParams] = React.useState(false);

    React.useEffect(() => {
        if (userData?.token !== undefined) return;

        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('provider_name');
        const database = urlParams.get('provider_database');
        const username = urlParams.get('provider_username');
        const session_id = urlParams.get('provider_session_id');
        const server = urlParams.get('provider_server');

        if (name === null || database === null || username === null || session_id === null || server === null) {
            setInvalidAuthQueryParams(true);
            return;
        }

        initiateLogin({ provider: { name, database, username, session_id, server } });
    }, []);

    let message = 'Authenticating...';
    let showSpinner = true;
    if (userData?.token === undefined) {
        if (userData?.lastLoginError !== undefined) {
            message = 'There was an error authenticating. Please try again later.';
            showSpinner = false;
        } else if (invalidAuthQueryParams) {
            message = 'Failed to detect auth params in url.';
            showSpinner = false;
        }
    }

    if (userData?.token !== undefined) {
        //login successful - redirect to map tab
        return <Redirect to={"/client/map"} />;
    }

    return (
        <div className={"LoginForm"}>
            <div className={"LoginForm-wrap"}>
                <ScaledIcon asset='logo' className={"LoginForm-logo"} alt="WeatherOptics" />
                <br />
                <Typography variant={'h5'} color={'textPrimary'} >{message}</Typography>
                <br />
                {showSpinner && <CircularProgress style={{ marginBottom: '30px' }} color="inherit" />}
            </div>
        </div>
    );
};